import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    startTime: null,
    timeLeft: 600,
    endTime: null
}

const timerSlice = createSlice({
    name: 'timer',
    initialState,
    reducers: {
        setStartTime: (state, action) => {
            state.startTime = action.payload
            state.endTime = action.payload + 10 * 60 * 1000
        },
        setTimeLeft: (state, action) => {
            state.timeLeft = action.payload
        },
        resetTimer: (state) => {
            state.startTime = null
            state.timeLeft = 600
            state.endTime = null
        },
    },
});

export const { setStartTime, setTimeLeft, resetTimer } = timerSlice.actions
export const selectTimer = (state) => state.timer
export default timerSlice.reducer;
