import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { axiosAuth } from "../config/axiosInstances";
import { API_XANO_CERTIFIED_BASE_URL } from "../config/api_config";
import { Api_url } from "../common/api_url";

export const fetchRazorPayStatus = createAsyncThunk(
    "razorPay/fetch",
    async ({ id, payment_id, order_id, isError, razorpay_signature, errorObj }) => {
        const response = await axios.post(
            "https://xgfy-czuw-092q.m2.xano.io/api:IfQi_Nyx/razorpay_certified_payment/payment_status",
            {
                id: id,
                payment_id:payment_id, 
                order_id:order_id, 
                isError:isError, 
                razorpay_signature:razorpay_signature, 
                errorObj:errorObj
            }
        );
        return response.data;
    }
)

export const fetchPaymentStatus = createAsyncThunk(
    'razorPay/fetchPaymentStatus',
    async ({ payment_id }) => {
        try {
            const response = await axios.get('https://xgfy-czuw-092q.m2.xano.io/api:IfQi_Nyx/razorpay_certified_payment/post_payment_new', {
                params: {
                    payment_id
                }
            })
            return response.data
            
        } catch (error) {
            
        }
    }
)

export const createPaymentOrder = createAsyncThunk(
    'createPaymentOrder',
    async ({ items, utm_source, scholarship_type }) => {
        try {
            const response = await axiosAuth.post(API_XANO_CERTIFIED_BASE_URL + Api_url.CreatePaymentOrder, {
                items,
                utm_source,
                scholarship_type
            })
            return response.data?.data
        } catch (error) {
            console.log(error.message)
            throw error
        }

    }
)

const razorPaySlice = createSlice({
    name: "razorPay",
    initialState: {},
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchRazorPayStatus.fulfilled, (state, action) => {
            return action.payload;
        })
    },
});

export const selectRazorPayStatus = (state) => state.razorPay;
export default razorPaySlice.reducer;