import React from 'react'
import { newFaqPersonaliseAddon } from '../../utilities/constants'
import Accordian from '../CertifiedPricingPage/Accordian'

const AddonFaq = () => {
    return (
        <div className='text-white mt-6'>
            <h2 className='text-xl font-bold mb-2'>Frequently Asked Questions</h2>
            {newFaqPersonaliseAddon.map(({ question, answer }, index) => (
                <Accordian title={question} key={index} index={index} length={newFaqPersonaliseAddon?.length} content={answer}/>
            ))}
        </div>
    )
}

export default AddonFaq