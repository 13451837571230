import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import useWindowSize from '../../hooks/useWindowSize';


const UserModal = React.forwardRef(({item}, ref) => {

    const { width } = useWindowSize()

    const truncatedText = (text, noOfChar) => {
        if (text.length <= noOfChar){
            return text
        } else {
            const res = text.slice(0, noOfChar) + '...'
            return res
        }
    }

    const postBody = (
        <div className='w-full flex justify-start bg-[#456C93] text-white rounded-lg'>
            <div className="relative w-[10%] mr-8">
                {item?.user_details?.profile_image ? 
                    <div className='w-full'>
                            <div className='w-[48px] h-[48px] my-2 ml-2'>
                                <img src={item?.user_details?.profile_image} alt="" height="40" width="40" className='rounded-full h-full w-full object-fit'/>
                            </div>
                            { item?.user_details?.linkedin_url ?
                                    <button className="absolute top-[35px] left-9"
                                        onClick={()=>{
                                        window.open(item?.user_details?.linkedin_url, '_blank')
                                    }}
                                    >
                                        <div className='w-[23px] h-[23px]'>
                                            <img src={"https://ik.imagekit.io/238r9emirjj/Content_player/LinkdIn.png?updatedAt=1681563676400"} alt="" className='w-full h-full object-cover' />
                                        </div>
                                    </button> : null
                            }
                    </div>
                        : 
                    <div className='w-full'>
                            <div className='rounded-full h-[48px] w-[48px] my-2 ml-2'>
                                <FontAwesomeIcon
                                className="text-slate-600 text-[48px]"
                                icon={faUserCircle}/>
                            </div>
                            { item?.user_details?.linkedin_url ? 
                                <button className="absolute top-[35px] left-9"
                                    onClick={()=>{
                                    window.open(item?.user_details?.linkedin_url, '_blank')
                                    }}
                                >
                                    <div className='w-[23px] h-[23px]'>
                                        <img src={"https://ik.imagekit.io/238r9emirjj/Content_player/LinkdIn.png?updatedAt=1681563676400"} alt="" className='w-full h-full object-cover' />
                                    </div>
                                </button> : null
                            }
                    </div>
                    }
            </div>
            <div className="flex w-[39%] flex-col justify-center items-start mr-1">
                <p className="text-sm w-full font-bold text-left truncate">{item?.user_details?.name}</p>
                <p className="text-xs w-full text-left font-medium opacity-50 truncate">{item?.job_designation}</p>
            </div>
            <div className='flex items-center justify-start w-[40%] mx-1'>
                <div className='w-[35px] h-[35px] mr-2'>
                    <img src={item?.company_details?.company_image?.url} alt="Company Name" className='w-full h-full object-cover'/>
                </div>
                <p className='text-xs flex-1 text-left leading-tight opacity-50 font-medium'>{item?.company_details?.company_name ? width < 768 ? truncatedText(item?.company_details?.company_name, 25) : width < 1024 ? truncatedText(item?.company_details?.company_name, 30): item?.company_details?.company_name :null}</p>
            </div>
        </div>
    )

    const content = ref ? 
    <div ref={ref} className="w-full rounded-lg">{postBody}</div> : 
    <div className="w-full rounded-lg">{postBody}</div>

  return content
})

export default UserModal
