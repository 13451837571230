import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useAddonContext } from '../../context/AddonFeatureContext'
import { useDispatch, useSelector } from 'react-redux'
import { selectPriceChart } from '../../redux/priceChartSlice'
import { nineNintyNine } from '.'
import { selectMCQDetails } from '../../redux/mcqSlice'
import { setShowCategoryModal, setShowCourseModal, setShowRelatedSubjectsModal } from '../../redux/otherDetailsSlice'

const SeoAdditionalFeatures = ({ skill_name }) => {

    const priceChart = useSelector(selectPriceChart)
    const quizDetails = useSelector(selectMCQDetails)

    const score = quizDetails?.quiz_score

    const skillIsBusinessManagement = nineNintyNine.includes(skill_name?.toLowerCase())

    const { threeCertificates, proMembershipSelected, personalisedSelected, handleToggle1, handleToggle2, handleToggle3, skillIsNotSeo, setRating, setStudent, setCourseName } = useAddonContext()

    const dispatch = useDispatch()

    const handlePersonaliseModal = (e) => {
        e.stopPropagation()
        setRating(5)
        setStudent(Math.floor((Math.random() * 1200) + 301))
        setCourseName(skill_name)
        dispatch(setShowCourseModal(true))
    }

    const handleCategoryModal = (e) => {
        e.stopPropagation()
        dispatch(setShowCategoryModal(true))
    }

    const handleRelatedSubjectsModal = (e) => {
        e.stopPropagation()
        dispatch(setShowRelatedSubjectsModal(true))
    }

    return (
        <div className='text-[#7FC241] mt-4'>
            <p className='text-sm md:text-xl font-medium mb-2'>Add Popular Benefits (Optional)</p>
            <div className='mt-2'>
                {skillIsNotSeo && <div className={`bg-bg-11 flex justify-between items-center py-3 px-4 mb-2.5 text-white cursor-pointer rounded-lg gap-2 transform transition-transform ${threeCertificates ? 'border-2 border-[#7FC241]' : ''}`} onClick={handleToggle1}>
                    <div className='flex items-center'>
                        <div className={`w-6 md:w-8 h-6 md:h-8 border rounded flex items-center justify-center flex-shrink-0 border-[#7FC241] shadow-boxShadow ${threeCertificates && 'bg-[#7FC241]'} mr-4`}>
                            <FontAwesomeIcon icon={faCheck} className='text-[#1F3F60]'/>
                        </div>
                            <p className='flex-1 text-left text-xs-xxs md:text-xl font-semibold'>{priceChart[2]?.product_description} <img src="https://ik.imagekit.io/238r9emirjj/Info.png?updatedAt=1704268543580" style={{
                                display: 'inline'
                            }} alt="" width={16} height={16} className='cursor-pointer' onClick={handleRelatedSubjectsModal}/></p>
                    </div>
                    <div className=''>
                        <p className='text-sm font-medium md:text-xl text-secondary-text'>&#8377;{skillIsBusinessManagement ? priceChart[37].price : priceChart[2].price}</p>
                        <p className='line-through text-sm md:text-base text-danger'>&#8377;{skillIsBusinessManagement ? priceChart[37].original_price : priceChart[2].original_price}</p>
                    </div>
                </div>}
                {score >= 40 && <div className={`bg-bg-11 flex justify-between items-center py-3 px-4 mb-2.5 text-white cursor-pointer gap-2 rounded-lg transform transition-transform ${personalisedSelected ? 'border-2 border-[#7FC241]' : ''}`} onClick={() => {
                    handleToggle3()
                }}>
                    <div className='flex items-center'>
                        <div className={`w-6 md:w-8 h-6 md:h-8 border rounded flex items-center justify-center flex-shrink-0 border-[#7FC241] shadow-boxShadow ${personalisedSelected && 'bg-[#7FC241]'} mr-4`}>
                            <FontAwesomeIcon icon={faCheck} className='text-[#1F3F60]'/>
                        </div>
                            <p className='flex-1 text-left text-xs-xxs md:text-xl font-semibold'>Personalised {skill_name} Course Built For You Basis Your Quiz <img src="https://ik.imagekit.io/238r9emirjj/Info.png?updatedAt=1704268543580" style={{
                                display: 'inline'
                            }} alt="" width={16} height={16} className='cursor-pointer' onClick={handlePersonaliseModal} /></p>
                    </div>
                    <div className=''>
                        <p className='text-sm font-medium md:text-xl text-secondary-text'>&#8377;{priceChart[4]?.price}</p>
                        <p className='line-through text-sm md:text-base text-danger'>&#8377;{priceChart[4]?.original_price}</p>
                    </div>
                </div>}
                {<div className={`bg-bg-11 flex justify-between items-center py-3 px-4 mb-2.5 text-white cursor-pointer rounded-lg gap-2 transform transition-transform ${proMembershipSelected ? 'border-2 border-[#7FC241]' : ''}`} onClick={() => {
                    handleToggle2()
                }}>
                    <div className='flex items-center'>
                        <div className={`w-6 md:w-8 h-6 md:h-8 border rounded flex items-center justify-center border-[#7FC241] shadow-boxShadow ${proMembershipSelected && 'bg-[#7FC241]'} mr-4`}>
                            <FontAwesomeIcon icon={faCheck} className='text-[#1F3F60]'/>
                        </div>
                        <p className='flex-1 text-left text-xs-xxs md:text-xl font-semibold'>Unlimited Access to {skill_name} & 1240+ More Certified Courses <img src="https://ik.imagekit.io/238r9emirjj/Info.png?updatedAt=1704268543580" style={{
                                display: 'inline'
                            }} alt="" width={16} height={16} className='cursor-pointer' onClick={handleCategoryModal} /></p>
                    </div>
                    <div className=''>
                        <p className='text-sm font-medium md:text-xl text-secondary-text'>&#8377;{priceChart[3]?.price}</p>
                        <p className='line-through text-sm md:text-base text-danger'>&#8377;{priceChart[3]?.original_price}</p>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default SeoAdditionalFeatures