import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { API_XANO_CERTIFIED_BASE_URL } from "../config/api_config"
import { Api_url } from "../common/api_url"

export const getProductDetails = createAsyncThunk(
    'getProductDetails',
    async ({ certified_product_id }) => {
        const maxRetires = 3
        let retries = 0
        while (retries < maxRetires) {
            try {
                const response = await axios.get(API_XANO_CERTIFIED_BASE_URL + Api_url.GetProductDetails, {
                    params: {
                        certified_product_id
                    }
                })
                if (response.status === 200) {
                    return { data: response.data?.data, productId: certified_product_id }
                }else{
                    retries += 1
                }
            } catch (error) {
                console.log(error.message)
                retries += 1
            }
        }
    }
)

const initialState = {
    1: {},
    2: {},
    3: {},
    4: {},
    5: {},
    6: {},
    7: {},
    8: {},
    9: {},
    10: {},
    11: {},
    12: {},
    13: {},
    14: {},
    15: {},
    16: {},
    17: {},
    18: {},
    19: {},
    20: {},
    21: {}
}
const priceChartSlice = createSlice({
    name: 'priceChart',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getProductDetails?.fulfilled, (state, action) => {
            const { data, productId } = action.payload
            state[productId] = data
        })
    }
})


export const selectPriceChart = (state) => state.priceChart
export default priceChartSlice.reducer