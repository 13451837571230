import Slider from 'react-slick'
import { useEffect } from 'react'
import { useState } from 'react'
import { useAddonContext } from '../../context/AddonFeatureContext'
import { selectRelatedSkills } from '../../redux/multiSkillSelectionSlice'
import { useSelector } from 'react-redux'

const LearnersCertification = () => {

    const relatedSkills = useSelector(selectRelatedSkills)


    const [ skillsArray, setSkillsArray ] = useState([])
    const { usersData } = useAddonContext()
    
    useEffect(() => {
        function shuffleArray(array) {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
        }
        function generateArrays(array, numArrays, minSize=1, maxSize=1) {
            const resultArrays = [];

            for (let i = 0; i < numArrays; i++) {
                const arrayCopy = [...array]; // Create a copy of the original array
                shuffleArray(arrayCopy); // Shuffle the copied array

                const arrayLength = Math.min(maxSize, Math.floor(Math.random() * (maxSize - minSize + 1)) + minSize);
                const newArray = arrayCopy.slice(0, arrayLength); // Pick the required number of unique elements
                resultArrays.push(newArray);
            }
          
            return setSkillsArray(resultArrays)
        }
        generateArrays(relatedSkills, 10, 3, 5)
    }, [relatedSkills])
    
    const settings = {
        dots: false,
        slidesToShow: 2,
        rows: 1,
        speed: 3000,
        autoplay: true,
        autoplaySpeed: 2000,
        easing: 'linear',
        cssEase: "linear",
        infinite: true,
        slidesToScroll: 2,
        arrows: false,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    infinite: true,
                    arrows: false,
                    swipeToSlide: false,
                }
            },
        ]
    }

    return (
        <div className=''>
            <Slider {...settings}>
                {usersData?.map((item, index) => (
                    <div key={index} className='w-full'>
                        <div className='w-[98%] h-[208px] bg-[#2D4F71] rounded-2xl p-3 md:p-4'>
                            <div className='flex gap-2 mb-2'>
                                <img src={item?.user_details?.profile_image} alt="" className='w-8 h-8 rounded-full object-cover' />
                                <div className='flex-1 leading-3'>
                                    <p className='text-xs font-medium w-full line-clamp-1'>{item?.user_details?.name}</p>
                                    <p className='text-[9px] text-white/70'>Unlocked {skillsArray[index]?.length} Certificates</p>
                                </div>
                            </div>
                            <div className='flex flex-col gap-1'>
                                {skillsArray[index]?.map((skill, skillNo) => (
                                    <div key={skillNo} className='text-[10px] text-white/90 truncate w-full rounded-lg text-center border-[1.5px] border-[#226BB3] py-1 px-2'>
                                        {skill}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    )
}

export default LearnersCertification