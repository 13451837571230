import Slider from 'react-slick';
import { useState } from 'react';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import useWindowSize from '../../hooks/useWindowSize';
import axios from 'axios';
 




const OurLearnersWorkAt = () => {
    
    const [usersData, setUsersData] = useState([])
    const { width } = useWindowSize()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://xgfy-czuw-092q.m2.xano.io/api:HYL0rVog/user_placements');
                setUsersData(response?.data?.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);
    
    const truncatedText = (text, noOfChar) => {
        if (text.length <= noOfChar){
            return text
        } else {
            const res = text.slice(0, noOfChar) + '...'
            return res
        }
    }

    const workAtsettings = {
        dots: false,
        slidesToShow: 3.1,
        rows: 1,
        speed: 3000,
        autoplay: true,
        autoplaySpeed: 3000,
        easing: 'linear',
        cssEase: "linear",
        infinite: true,
        arrows: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                }
            },

            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3.5,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2.1,
                    infinite: true,
                    dots: false,
                    arrows: false,
                    swipeToSlide: false
                }
            },
        ]
    };
    
  return (
        <>
            <div className='w-full arrows'>
                <Slider {...workAtsettings}>
                    {usersData?.map((item, index)=> (
                        <div key={index}>
                            <div className="h-[9.5rem] mx-1 px-3 py-2 min-w-[8.3rem] bg-secondary_background  flex flex-col justify-start items-center rounded-xl shadow-md my-1">
                                
                                    {item?.user_details?.profile_image ? 
                                    <div className="relative">
                                        <div className='w-[50px] h-[50px]'>
                                            <img src={item?.user_details?.profile_image} alt="" height="40" width="40" className='rounded-full h-full w-full object-fit'/>
                                        </div>
                                        { item?.user_details?.linkedin_url ?
                                                <button className="absolute top-8 left-8"
                                                    onClick={()=>{
                                                    window.open(item?.user_details?.linkedin_url, '_blank')
                                                }}
                                                >
                                                    <div className='w-[20px] h-[20px]'>
                                                        <img src={"https://ik.imagekit.io/238r9emirjj/Content_player/LinkdIn.png?updatedAt=1681563676400"} alt="" className='w-full h-full object-cover' />
                                                    </div>
                                                </button> : null
                                        }
                                    </div>
                                    : 
                                    <div className='relative'>
                                        <div className='rounded-full h-[50px] w-[50px]'>
                                            <FontAwesomeIcon
                                            className="text-slate-600 text-[50px]"
                                            icon={faUserCircle}/>
                                        </div>
                                        { item?.user_details?.linkedin_url ? 
                                            <button className="absolute top-8 left-8"
                                                onClick={()=>{
                                                window.open(item?.user_details?.linkedin_url, '_blank')
                                                }}
                                            >
                                                <div className='w-[20px] h-[20px]'>
                                                    <img src={"https://ik.imagekit.io/238r9emirjj/Content_player/LinkdIn.png?updatedAt=1681563676400"} alt="" className='w-full h-full object-cover' />
                                                </div>
                                            </button> : null
                                        }
                                    </div>
                                    } 
                                            
                                <p className='text-sm w-full text-center pt-2 font-semibold truncate'>
                                    {item?.user_details?.name}
                                </p>
                                <p className='text-xs w-full font-medium text-center leading-3 truncate'>{item?.job_designation}</p>
                                <div className='flex justify-start mt-2 mx-2 items-center'>
                                    <div></div>
                                    <div className='w-[38px] h-[38px] mr-1'>
                                        <img src={item?.company_details?.company_image?.url} alt="" className='w-full h-full object-cover'/>
                                    </div>
                                    <p className='text-xs font-medium truncate'>{item?.company_details?.company_name ? width < 768 ?  truncatedText(item?.company_details?.company_name, 10) : truncatedText(item?.company_details?.company_name, 12) :null}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </>
  )
}

export default OurLearnersWorkAt