import React from 'react'
import AchievementSection2 from '../../components/AchievementSection2'
import { successStories } from '../../utilities/constants'

const SuccessStoriesSection2 = () => {

    

    // useEffect(() => {
    //     const storiesAndReviews = async () => {
    //         try{
    //             const response = await axios.get('https://xgfy-czuw-092q.m2.xano.io/api:HYL0rVog/user_stories_and_reviews');
    //             setSuccessStories(response?.data?.data?.success_stories);            
    //         }
    //         catch(err){
    //             console.log(err);
    //         }
    //     };
    //     storiesAndReviews();
    // }, [])

    return (
        <AchievementSection2 achievements={successStories}/>
    )
}

export default SuccessStoriesSection2