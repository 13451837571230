import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosAuth, axiosNoAuth } from '../config/axiosInstances'
import { API_XANO_CERTIFIED_BASE_URL } from '../config/api_config'
import { Api_url } from '../common/api_url'

export const fetchPopularSkills = createAsyncThunk(
    'fetchPopularSkills/searchPopularSkills',
    async ({ searchValue }) => {
        const response = await axiosNoAuth.post('https://ninjacurate.learntube.ai/fast_search',
            {
                skill: searchValue
            })
        return response.data

    }
)

export const customCertifiedBundle = createAsyncThunk(
    'customCertifiedBundle',
    async ({ course_list, bundle_type, certified_user_skill_id }) => {
        try {
            const response = await axiosAuth.post(API_XANO_CERTIFIED_BASE_URL + Api_url.CreateBundle, {
                course_list, bundle_type, certified_user_skill_id
            })

            return response.data
        } catch (error) {
            throw error
        }
    }
)

const initialState = {
    searchResults: [],
    selectedItems:[],
    selectedFromSearchResults: [],
    customBundle: {}
}

const searchSlice = createSlice({
    name: 'searchResults',
    initialState,
    reducers: {
        setSelectedItems: (state, action) => {
            if (state.selectedItems.includes(action.payload)){
                const newItems = state.selectedItems.filter((skill) => skill !== action.payload)
                state.selectedItems = newItems
            }else{
                state.selectedItems = [...state.selectedItems, action.payload]
            }
        },
        setSelectedSearchedItems: (state, action) => {
            if (state.selectedFromSearchResults.includes(action.payload)){
                const newItems = state.selectedFromSearchResults.filter((skill) => skill !== action.payload)
                state.selectedFromSearchResults = newItems
            }else{
                state.selectedFromSearchResults = [...state.selectedFromSearchResults, action.payload]
            }
        },
        resetResults: () => initialState
    },
    extraReducers: (builder)=> {
        builder
        .addCase(fetchPopularSkills.fulfilled, (state, action) => {
            state.searchResults =  action.payload
        })
        .addCase(customCertifiedBundle.fulfilled, (state, action) => {
            state.customBundle = action.payload
        })
    }
})

export const { setSelectedItems, setSelectedSearchedItems, resetResults } = searchSlice.actions
export const selectSearchResults = (state) => state.searchResults?.searchResults
export const selectSelectedItems = (state) => state.searchResults?.selectedItems
export const selectSelectedFromSearchResults = (state) => state.searchResults?.selectedFromSearchResults
export const selectCustomBundle = (state) => state.searchResults?.customBundle
export default searchSlice.reducer