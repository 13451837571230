import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { axiosAuth } from "../config/axiosInstances"
import { API_XANO_CERTIFIED_BASE_URL } from "../config/api_config"
import { Api_url } from "../common/api_url"

export const fetchSuggestedCourseDetails = createAsyncThunk(
    'fetchSuggestedCourseDetails',
    async ({ certified_bundle_course_id, type, subject, goal }) => {
        try {
            const response = await axiosAuth.get(API_XANO_CERTIFIED_BASE_URL + Api_url.CourseSyllabus,
            { 
                params: { certified_bundle_course_id, Type: type, subject, goal }
            })
            if (response.status === 200){
                return {
                    subject,
                    syllabus: response.data?.data
                }
            }
        } catch (error) {
            console.log(error.message)
        }
    }
)

const initialState = {
    suggestedCourseSyllabus: [],
    paid: false,
    error: false
}

const newPostPaymentSlice = createSlice({
    name: 'newPostPaymentSlice',
    initialState,
    reducers: {
        setSuggestedSyllabus: (state, action) => {
            state.suggestedCourseSyllabus = action.payload
        },
        setHasPaid: (state, action) => {
            state.paid = action.payload
        },
        resetCourseSyllabus: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSuggestedCourseDetails.fulfilled, (state, action) => {
            const { subject, syllabus } = action.payload
            if (subject && syllabus){
                if (!state.suggestedCourseSyllabus?.some((item) => item.course_name === subject)){
                    state.suggestedCourseSyllabus = [ ...state.suggestedCourseSyllabus, { course_name: subject, syllabus: syllabus }]
                }
            }
        })
        builder.addCase(fetchSuggestedCourseDetails.rejected, (state) => {
            state.error = true
        })
    }
})

export const { resetCourseSyllabus, setSuggestedSyllabus, setHasPaid } = newPostPaymentSlice.actions
export const selectSuggestedCoursesSyllabus = (state) => state.newPostPaymentDetails.suggestedCourseSyllabus
export const selectPaidStatus = (state) => state.newPostPaymentDetails.paid
export default newPostPaymentSlice.reducer