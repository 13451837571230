import { Dialog } from '@headlessui/react'
import React from 'react'
import { selectshowCertificateImageModal, setShowCertificateImageModal } from '../../redux/postPaymentSlice'
import { useDispatch, useSelector } from 'react-redux'
import CertificateImageModalInside from './CertificateImageModalInside'

const CertificateImageModal = () => {

    const showCertificateImageModal = useSelector(selectshowCertificateImageModal)

    const dispatch = useDispatch()

    const handleModal = () => {
        dispatch(setShowCertificateImageModal(false))
    }

    return (
        <div>
            <Dialog
                open={showCertificateImageModal}
                onClose={handleModal}
                as='div'
                className="absolute inset-0 flex flex-col mx-auto text-white"
            >
                <Dialog.Overlay className="fixed inset-0 mx-auto z-50 bg-black opacity-80" />
                <Dialog.Title/>
                <Dialog.Description/>
                <div className={`bg-custom-bg max-w-2xl rounded-2xl w-[90%] mx-auto my-auto z-[80] relative max-h-[650px] md:max-h-[550px] overflow-y-scroll`}
                    style={{
                        boxShadow: '0px 0px 20px 0px #0000004D'
                    }}
                >
                    <CertificateImageModalInside
                        handleModal={handleModal}
                    />
                </div>
            </Dialog>
        </div>
    )
}

export default CertificateImageModal