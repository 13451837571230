import { certifiedFaq } from '../../utilities/constants'
import FaqAccordian from './FaqAccordian'

const CertifiedFAQSection = ({ sentinelRef }) => {
    return (
        <div className='mt-6'>
            <h2 className="text-2xl font-semibold text-left mb-2">Frequently Asked Questions</h2>
            {certifiedFaq.map(({ question, answer }, index) => {
                return index === 2 ? <FaqAccordian ref={sentinelRef} title={question} key={index} index={index} length={certifiedFaq?.length} content={answer}/> : <FaqAccordian title={question} key={index} index={index} length={certifiedFaq?.length} content={answer}/>
            })}
        </div>
    )
}

export default CertifiedFAQSection