import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {

    const navigate = useNavigate()

    useEffect(() => {
        setTimeout(() => {
            navigate('/')
        }, 3000)
    },[])

  return (
    <div className='flex-1 flex flex-col justify-center items-center text-white'>
        <h1 className='text-4xl font-bold'>404</h1>
        <p className='text-xl font-semibold'>Page Not Found</p>
        <p className='text-xl font-semibold'>Redirecting to Home Page</p>
    </div>
  )
}

export default PageNotFound