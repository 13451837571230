import store from '../store'
import axios from 'axios'

export const axiosAuth = axios.create({

})

export const axiosNoAuth = axios.create({

})

axiosAuth.interceptors.request.use(
    (config) => {
        const token = store.getState().authenticationDetails.token;
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
        }
        return config
    },
    (error) => {
        return Promise.reject(error);
    }
)

axiosAuth.interceptors.response.use(
    (response) => response,
    (error) => {
        const { status } = error.response;
        if (status === 401) {
            throw new Error('Unauthorized');
        }
    return Promise.reject(error);
})
