import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    hours: 24,
    minutes: 0,
    seconds: 0,
    isRunning: false
}

const dayTimerSlice = createSlice({
    name: 'timer',
    initialState,
    reducers: {
        decrementSeconds: (state) => {
            if (state.seconds > 0) {
                state.seconds -= 1;
            } else if (state.minutes > 0 || state.hours > 0) {
                state.seconds = 59;
                if (state.minutes > 0) {
                    state.minutes -= 1;
                } else if (state.hours > 0) {
                    state.minutes = 59;
                    state.hours -= 1;
                }
            }
        },
        startTimer: (state) => {
            state.isRunning = true;
        },
        dayResetTimer: (state) => {
            state.hours = 24;
            state.minutes = 0;
            state.seconds = 0;
            state.isRunning = false;
        }
    },
})

export const { decrementSeconds, startTimer, dayResetTimer } = dayTimerSlice.actions
export const selectDayTimer = (state) => state.dayTimer
export default dayTimerSlice.reducer;
