import React from 'react'

const ProjectsAndOutcomes = ({ list }) => {
    return ( 
        list?.slice(0, 5)?.map((item, index) => (
            item?.length > 3 && <div key={index} className={`bg-bg-2 ${index === 0 ? 'rounded-t-xl' : index === list?.slice(0,5)?.length - 1 ? 'rounded-b-xl' : ''} px-2 py-2.5 flex items-center gap-2`}>
                <img src="https://ik.imagekit.io/238r9emirjj/LinkdinUsers/Check%20Mark%202.svg?updatedAt=1701935142830" width={20} height={20} alt="" />
                <p className='text-sm font-medium'>{item?.slice(3)}</p>
            </div>
        ))
    )
}

export default ProjectsAndOutcomes