import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { selectSuggestedCourseDetail } from '../../redux/postPaymentSlice';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SyllabusDetails = ({ lessonRef, loading }) => {

    const courseDetail = useSelector(selectSuggestedCourseDetail)

    const [ showLessons, setShowLessons ] = useState([]);
    
    useEffect(() => {
        if (courseDetail?.syllabus) {
            setShowLessons([true, ...Array(courseDetail?.syllabus?.length > 0 ? courseDetail?.syllabus?.length - 1 : 0).fill(false)]);
        }
    }, [courseDetail?.syllabus]);

    const handleToggleLessons = (index) => {
        setShowLessons((prevShowLessons) => {
            const updatedShowLessons = [...prevShowLessons];
            updatedShowLessons[index] = !prevShowLessons[index];
            return updatedShowLessons;
        })
    }

    return (
        <div className={`max-w-2xl w-full mx-auto my-auto z-50 relative overflow-y-scroll`}>
            {!loading ? <div className='flex flex-col gap-2'>
                {courseDetail?.syllabus?.slice(0, courseDetail?.syllabus?.length)?.map((item, index) => (
                    <div key={index} className='bg-bg-2 rounded-xl p-3' >
                        <div className='flex justify-between items-center cursor-pointer' onClick={() => handleToggleLessons(index)}>
                            <div className='tracking-wide'>
                                <p className='font-semibold leading-[18px]'>{item?.mskill_name}</p>
                                <div className='flex items-center text-sm opacity-50 mt-0.5'>
                                    <p>{item?.lesson_list?.length} lessons</p>
                                    <div className='w-1 h-1 rounded-full bg-[#92B3BE] mx-2'></div>
                                    <p>{Math.floor((item?.lesson_list?.length * 15)/60) === 0 ? '' : Math.floor((item?.lesson_list?.length * 15)/60) === 1 ? `${Math.floor((item?.lesson_list?.length * 15)/60)} hour` : `${Math.floor((item?.lesson_list?.length * 15)/60)} hours`} {(item?.lesson_list?.length * 15)%60 !== 0 ? `${(item?.lesson_list?.length * 15)%60} minutes` : ''}</p>
                                </div>
                            </div>
                            <div className="">
                                {showLessons[index] ? (
                                    <FontAwesomeIcon
                                        icon={faChevronUp}
                                        className="text-xl font-extrabold"
                                    />
                                    ) : (
                                    <FontAwesomeIcon
                                        icon={faChevronDown}
                                        className="text-xl font-extrabold"
                                    />
                                )}
                            </div>
                        </div>
                        <div>{showLessons[index] && (
                            <div>{item?.lesson_list?.map((lesson, lessonIndex) => (
                                <div key={lessonIndex} className='w-full'>
                                    <div  className="flex justify-start items-center mt-3">
                                        <div className='w-3 h-3 bg-white rounded-full'>
                                        </div>
                                        <p className="ml-3 text-sm font-semibold leading-5">{lesson}</p>
                                    </div>
                            </div>))}
                        </div>)}
                    </div>
                </div>))}
            </div> : <div className='w-full animate-pulse'>
                {Array.from({ length: 5}, (_, index) => (
                    <div key={index} className={`bg-[#14395F] border-b px-2 py-6 animate-pulse flex items-center gap-2`}>
                    
                    </div>
                ))}
            </div>}
        </div>
    )
}

export default SyllabusDetails