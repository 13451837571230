import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectUserDetails } from '../../redux/otherDetailsSlice'
import { Mixpanel } from '../../config/Mixpanel'
import constants from '../../utilities/constants'

const ThankYou = () => {

  const userDetails = useSelector(selectUserDetails)
  const userName = userDetails?.user_name

  useEffect(() => {
    Mixpanel.track(constants.Mixpanel.THANK_YOU_PAGE_LOADED)
  }, [])

  return (
    <div className='flex-1 text-white text-center flex flex-col justify-center items-center'>
      <div className='w-[80%] md:w-1/4'>
        <img src="https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/man-making-successful-payment_1.png?updatedAt=1686478795996" alt="" />
      </div>
        <h2 className='text-2xl font-medium'>Congratulations, {userName} Your <span className='font-bold text-span_color'>Purchase Is Complete!</span> </h2>
        <p className='opacity-80 mt-5'>Your certificate will be whatsapped to you, within 24 hours.</p>
    </div>
  )
}

export default ThankYou