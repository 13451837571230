import { faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { fetchPopularSkills } from '../../redux/searchSlice'
import useDebounce from '../../hooks/useDebounce'
import { useDispatch } from 'react-redux'

const SearchSkills = ({ skillName, setSkillName}) => {

    const dispatch = useDispatch()
    
    const { debouncedValue } = useDebounce(skillName)

    useEffect(() => {
        if(debouncedValue){
            dispatch(fetchPopularSkills({ searchValue: debouncedValue }))
        }
    }, [debouncedValue, dispatch])

    const resetDebouncedValue = () => setSkillName('')

    const handleSubmit = (e) => {
        e.preventDefault()
    }

    return (
        <div>
            <form className='flex flex-col' onSubmit={handleSubmit}>
                <div className='relative w-full'>
                    <div className='absolute inset-y-0 left-0 pl-3 flex items-center'>
                        <FontAwesomeIcon icon={faMagnifyingGlass} className={`${skillName ? 'text-white opacity-50' : 'text-[#6D90B4]'} text-lg`}/>
                    </div>
                    <div className='w-full flex'>
                        <input 
                            type="text"
                            value={skillName}
                            onChange={(e) => setSkillName(e.target.value)}
                            placeholder='Find any skill you want'
                            className={`w-full p-2.5 h-[45px] text-base pl-10 skill_search rounded-lg bg-secondary_background border border-[#858A8F] placeholder:text-sm md:placeholder:text-base placeholder:text-[#6D90B4]`}
                        />
                    </div>
                    {skillName && <div className='absolute inset-y-0 cursor-pointer right-0 pr-3 flex items-center' onClick={resetDebouncedValue}>
                        <FontAwesomeIcon icon={faXmark} className='text-3xl'/>
                    </div>}
                </div>
            </form>
        </div>
    )
}

export default SearchSkills