// require('dotenv').config();
export const ENV = process.env.REACT_APP_ENV
export const API_XANO_BASE_URL = process.env.REACT_APP_XANO_BASE_URL
export const API_XANO_SEARCH_BASE_URL = process.env.REACT_APP_XANO_SEARCH_BASE_URL
export const API_XANO_SEARCH_DROPDOWN_BASE_URL = process.env.REACT_APP_XANO_SEARCH_DROPDOWN_BASE_URL
export const API_XANO_AUTH_URL = process.env.REACT_APP_XANO_AUTH_URL
export const XANO_GOOGLE_CLIENT_ID = process.env.REACT_APP_XANO_GOOGLE_CLIENT_ID
export const REK = process.env.REACT_APP_REK
export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN
export const API_XANO_SYNC_GOOGLE_CONTACT = process.env.REACT_APP_XANO_GOOGLE_CONTACTS
export const API_GET_LOCATION_WITHOUT_PERMISSION = process.env.REACT_APP_GET_LOCATION_WITHOUT_PERMISSION
export const API_KEY_LOCATON_WITH_PERMISSION = process.env.REACT_APP_GET_LOCATION_WITH_PERMISSION_API_KEY
export const API_XANO_RAZORPAY_BASE_URL = process.env.REACT_APP_XANO_RAZORPAY_BASE_URL
export const RAZORPAY_KEY = process.env.REACT_APP_RAZORPAY_KEY
//export const API_ABLY_KEY = process.env.REACT_APP_ABLY_API_KEY
// export const API_XANO_VERIFICATION_BASE_URL = process.env.REACT_APP_XANO_AUTH_VERIFICATION_BASE_URL
// export const API_XANO_AUTH_SERVICE_BASE_URL = process.env.REACT_APP_XANO_AUTH_SERVICE_URL
// export const RAZORPAY_SECRET = process.env.REACT_APP_RAZORPAY_SECRET
export let GOOGLE_CONTACTS_REDIRECT_URI;
export let GOOGLE_CONTACTS_REDIRECT_URI_LEADERBOARD;
export let GOOGLE_REDIRECT_URI;
export let LINKEDIN_REDIRECT_URI;
export const API_XANO_AUTH_FACEBOOK_URL = process.env.REACT_APP_XANO_AUTH_FACEBOOK_URL
export const XANO_FACEBOOK_APP_ID = process.env.REACT_APP_XANO_FACEBOOK_APP_ID
export const API_XANO_UTM_URL = process.env.REACT_APP_UTM_URL
export const API_XANO_AUTH_BASE_URL = process.env.REACT_APP_XANO_AUTH_BASE_URL
export const API_XANO_CERTIFIED_BASE_URL = process.env.REACT_APP_CERTIFIED_APP_BASE_URL
export const LINKEDIN_CLIENT_ID = process.env.REACT_APP_LINKDIN_CLIENT_ID