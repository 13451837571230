import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API_XANO_CERTIFIED_BASE_URL } from '../config/api_config'
import { Api_url } from '../common/api_url'
import axios from 'axios'
import { axiosAuth } from '../config/axiosInstances'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

export const getUserDetails = createAsyncThunk(
    'getUserDetails',
    async (token) => {
        try {
            const response = await axiosAuth.get(API_XANO_CERTIFIED_BASE_URL + Api_url.GetUserDetail, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            return response.data
        } catch (error) {
            console.log(error)
        }
    }
)

export const fetchAuthenticationToken = createAsyncThunk(
    'fetchAuthenticationToken',
    async ({  certified_user_skill_id, email, phone_number, name, password }) => {
        const maxTries = 3
        let tries = 1
        while (tries < maxTries) {
            try {
                const response = await axios.post(API_XANO_CERTIFIED_BASE_URL + Api_url.Authentication, {
                    certified_user_skill_id, email, phone_number, name, password
                })
                if (response.data?.result === 'success'){
                    return response.data?.data
                }else{
                    const navigate = useNavigate()
                    toast.error("Something Went Wrong")
                    return navigate('/')
                }
            } catch (error) {
                console.log(error.message)
                tries += 1
                if (tries === maxTries){
                    throw error
                }
            }
        }
    }
)

export const cretaeCertificateId = createAsyncThunk(
    'cretaeCertificateId',
    async ({ certified_user_skill_id }) => {
        try {
            const response = await axiosAuth.post(API_XANO_CERTIFIED_BASE_URL + Api_url.CreateCertificateId, {
                certified_user_skill_id
            })
            return response.data.data
        } catch (error) {
            console.log(error.message)
            throw error
        }
    }
)

export const getCertificateLink = createAsyncThunk(
    'getCertificateLink',
    async ({ unique_certificate_id }) => {
        try {
            const response = await axiosAuth.post(API_XANO_CERTIFIED_BASE_URL + Api_url.GetCertificateLink, {
                unique_certificate_id
            })
            return response.data?.data
        } catch (error) {
            throw error
        }
    }
)

export const shareInLinkedIn = createAsyncThunk(
    'shareInLinkedIn',
    async ({ certificate_id, certificate_type }) => {
        try {
            const response = await axios.get('https://xgfy-czuw-092q.m2.xano.io/api:certificate/validate/url_beta', {
                params: {
                    certificate_id, certificate_type
                }
            })
            return response.data?.data
        } catch (error) {
            console.log(error.message)
        }
    }
)

const initialState = {
    token: '',
    certificate: {
        unique_id: {},
        certificate_link: {},
        share_linkedin: {}
    },
    userDetails: {}
}

const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        setAuthToken: (state, action) => {
            state.token = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAuthenticationToken.fulfilled, (state, action) => {
            state.token = action.payload
        })
        builder.addCase(cretaeCertificateId.fulfilled, (state, action) => {
            state.certificate.unique_id = action.payload
        })
        builder.addCase(getCertificateLink.fulfilled, (state, action) => {
            state.certificate.certificate_link = action.payload
        })
        builder.addCase(shareInLinkedIn.fulfilled, (state, action) => {
            state.certificate.share_linkedin = action.payload
        })
        builder.addCase(getUserDetails.fulfilled, (state, action) => {
            state.userDetails = action.payload
        })
    }
})

export const { setAuthToken } = authenticationSlice.actions
export const selectAuthToken = (state) => state.authenticationDetails?.token
export const selectUniqueId = (state) => state.authenticationDetails?.certificate?.unique_id
export const selectCertificateLink = (state) => state.authenticationDetails?.certificate?.certificate_link
export const selectLinkedInUrl = (state) => state.authenticationDetails?.certificate?.share_linkedin
export const selectUserDetailsFromToken = (state) => state.authenticationDetails?.userDetails
export default authenticationSlice.reducer