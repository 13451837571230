/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog } from '@headlessui/react'
import React, { useEffect, useState } from 'react'
import { useAddonContext } from '../context/AddonFeatureContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons'
import { courses, skills } from '../utilities/constants'
import { filledstars, notFilledstars } from '../pages/NewPostPaymentFeature/CourseDetailsModal'
import { toast } from 'react-toastify'
import useDebounce from '../hooks/useDebounce'
import { useDispatch, useSelector } from 'react-redux'
import { selectShowCategoryModal, setShowCategoryModal } from '../redux/otherDetailsSlice'

export const SkillsListToShow = ({ skills, ratings, students }) => {
    
    return <div className='flex flex-col gap-1.5'>
        {skills.map((item, index) => (
            <div key={index} className='bg-bg-2 flex items-center rounded-xl py-2.5 px-3'>
                <h3 className='flex-1 text-sm font-medium'>{item?.skill_name}</h3>
                <div className='flex flex-col gap-0.5 items-center mt-1'>
                    <div className='flex'>
                        {filledstars(ratings[index])}
                        {notFilledstars(ratings[index])}
                    </div>
                    <p className='text-xs text-white/50 mt-0.5'>{students[index]} Students</p>
                </div>
            </div>
        ))}
    </div>
}

const CategoryModal = ({ handleAddon, optionThreeSelected }) => {

    const showCategoryModal = useSelector(selectShowCategoryModal)

    const [ searchParam, setSearchParam ] = useState('')
    const [ searchedResult, setSearchedResult ] = useState([])

    const { skillsToShow, setProMembershipSelected, proMembershipSelected, showSkillList, setShowSkillList, ratings, setRatings, students, setStudents, courseNameInModal, imageLink, handleSkillSelection } = useAddonContext()

    const { debouncedValue } = useDebounce(searchParam, 300)

    const dispatch = useDispatch()

    const handleCart = () => {
        if (optionThreeSelected){
            return toast.success('Already In Cart')
        }
        if (handleAddon){
            handleAddon('core_product_purchase')
            toast.success('Added to cart')
        }else{
            if (proMembershipSelected){
                toast.success('Already In Cart')
            }else{
                setProMembershipSelected(true)
                toast.success('Added to cart')
            }
        }
    }

    useEffect(() => {
        if (debouncedValue){
            let result = []
            for (let item of skills) {
                let filteredArray = item.filter((arr) => arr?.skill_name?.toLowerCase()?.includes(debouncedValue.toLowerCase()))
                result = [...result, ...filteredArray]
            }
            setSearchedResult(result)
        }
    }, [debouncedValue])
    
    useEffect(() => {
        setRatings(Array.from({ length: searchedResult?.length }, () => Math.random() < 0.5 ? 4.5 : 5))
        setStudents(Array.from({ length: searchedResult?.length }, () => Math.floor(Math.random() * (1500 - 1300 + 1)) + 1300))
    }, [debouncedValue, searchedResult])

    const handleModal = () => {
        dispatch(setShowCategoryModal(false))
    }

    return (
        <div>
            <Dialog
                open={showCategoryModal}
                onClose={handleModal}
                as='div'
                className="absolute inset-0 flex flex-col mx-auto text-white"
            >
                <Dialog.Overlay className="fixed inset-0 mx-auto z-50 bg-black opacity-80" />
                <Dialog.Title/>
                <Dialog.Description/>
                <div className={`bg-custom-bg max-w-2xl rounded-2xl w-[90%] mx-auto my-auto z-[80] relative max-h-[650px] md:max-h-[550px] overflow-y-scroll`}
                    style={{
                        boxShadow: '0px 0px 20px 0px #0000004D'
                    }}
                >
                    <div className='p-3'>
                        <div className='absolute right-3 top-4 cursor-pointer' onClick={handleModal}>
                            <FontAwesomeIcon icon={faXmark} className='text-2xl'/>
                        </div>
                        {showSkillList && <div className='absolute left-3 top-4 cursor-pointer' onClick={() => {
                            setShowSkillList(false)
                        }}>
                            <FontAwesomeIcon icon={faChevronLeft} className='text-2xl'/>
                        </div>}
                        <h3 className='text-center text-2xl font-semibold'>Explore All Courses</h3>
                        <div className='relative mt-4'>
                            <form>
                                <div className='absolute inset-y-0 left-0 pl-3 flex items-center'>
                                    <FontAwesomeIcon icon={faMagnifyingGlass} className='text-lg text-[#6D90B4]'/>
                                </div>
                                <input 
                                    type="text"
                                    value={searchParam}
                                    onChange={(e) => setSearchParam(e.target.value)}
                                    placeholder='Search for any course'
                                    className='w-full py-2.5 bg-bg-2 border-none rounded-lg placeholder:text-sm placeholder:font-medium placeholder:text-[#6D90B4] pl-10 job-role'
                                />
                            </form>
                        </div>
                        <div className='mt-3'>
                            <h2 className='mb-2 text-xl font-semibold'>{searchParam ? 'Search Results' : showSkillList ? (<><img src={imageLink} alt='' className='inline mr-2'/>{courseNameInModal}</>) : "Select Categories"}</h2>
                        </div>
                        {!debouncedValue ? (!showSkillList ? <div className='flex flex-col gap-1'>
                            {courses.map((item, index) => (
                                <div key={index} className='bg-bg-2 flex items-center rounded-lg pl-2 pr-3 py-2.5 gap-2 cursor-pointer' onClick={() => handleSkillSelection(index)}>
                                    <img src={item.image} alt="" />
                                    <p className='flex-1'>{item.name}</p>
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </div>
                            ))}
                        </div> : <SkillsListToShow
                            skills={skillsToShow}
                            ratings={ratings}
                            students={students}
                            imageLink={imageLink}
                        />) : (searchedResult?.length > 0 ? <SkillsListToShow
                            skills={searchedResult}
                            ratings={ratings}
                            students={students}/>
                        : <p>No Result</p>)}
                        </div>
                    <div className='sticky bg-[#2D4F71] flex justify-between items-center left-0 bottom-0 py-3 w-full px-3'>
                        <p className='text-xl font-medium'>₹599<span className='text-danger text-sm'>₹</span><span className='text-danger text-sm line-through'>1799</span></p>
                        <div className='bg-[#7FC241] rounded-lg text-black font-semibold py-2 px-4' onClick={handleCart}>
                            Add To Cart
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default CategoryModal