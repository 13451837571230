import { faChevronLeft, faChevronRight, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog } from '@headlessui/react'
import React, { useState } from 'react'
import { selectLandingPageDetails, selectShowRelatedSubjectsModal, setShowRelatedSubjectsModal } from '../../redux/otherDetailsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { selectRelatedSkills } from '../../redux/multiSkillSelectionSlice'
import { courses, iconList, skills } from '../../utilities/constants'
import { selectSearchResults } from '../../redux/searchSlice'
import SearchSkills from './SearchSkills'

const RelatedSubjectsModal = () => {

    const landingPage = useSelector(selectLandingPageDetails)
    const relatedSkills = useSelector(selectRelatedSkills)
    const searchResults = useSelector(selectSearchResults)
    const showRelatedSubjectsModal = useSelector(selectShowRelatedSubjectsModal)

    const skill_name = landingPage?.skill_name

    const [ showCategories, setShowCategories ] = useState(false)
    const [ showSkills, setShowSkills ] = useState(false)
    const [ skillName, setSkillName ] = useState('')
    const [ skillIndex, setSkillIndex ] = useState(0)

    const dispatch = useDispatch()

    const handleBack = () => {
        if (showSkills){
            setShowSkills(false)
        }else if (showCategories){
            setShowCategories(false)
        }
    }

    const handleModal = () => {
        dispatch(setShowRelatedSubjectsModal(false))
    }
    
    return (
        <div>
            <Dialog
                open={showRelatedSubjectsModal}
                onClose={handleModal}
                as='div'
                className="absolute inset-0 flex flex-col mx-auto"
            >
                <Dialog.Overlay className="fixed inset-0 mx-auto z-50 bg-black opacity-80" />
                <Dialog.Title/>
                <Dialog.Description/>
                <div className='p-3 w-[92%] max-w-xl my-auto z-50 max-h-[500px] flex flex-col mx-auto rounded-2xl relative text-[#B0C1CD] overflow-y-scroll border border-[#CFE4F3]' style={{
                    background: 'radial-gradient(53.11% 56.94% at 50% 50%, #26496A 0%, #182C3F 100%)',
                    boxShadow: '0px 0px 20px 0px #0000004D'
                }}>
                    
                    {!showCategories && <>
                        <div className='flex justify-between'>
                            <h2 className='text-white flex-1'>View All Available Subjects</h2>
                            <div className='cursor-pointer' onClick={handleModal}>
                                <FontAwesomeIcon icon={faXmark} className='text-2xl' />
                            </div>
                        </div>
                        <p className='text-xs'>You can select any of them post purchase</p>
                        <div className='flex flex-col mt-5 flex-1'>
                            <h3 className='text-white text-sm font-medium mb-3'>Related to <span className='text-span_color'>{skill_name}</span></h3>
                            <div className='max-h-[250px] overflow-y-scroll flex flex-wrap gap-2 pr-1 takeaway'>
                                {relatedSkills?.map((item) => (
                                    <div className='bg-[#2D4F71] text-[#70B1DD] h-fit text-xs p-2 rounded-lg'>
                                        {item}
                                    </div>
                                ))}
                            </div>
                            <div className='mt-4'>
                                <h4 className='text-sm font-medium text-white mb-2 mt-3'>Couldn’t find what you’re looking for?</h4>
                                <div className='mb-4'>
                                    <SearchSkills
                                        skillName={skillName}
                                        setSkillName={setSkillName}
                                    />
                                </div>
                                {skillName && searchResults?.length > 0 ? <div className='flex gap-2 flex-wrap'>
                                    {searchResults.map((item, index) => (
                                    <div key={index} className={`bg-[#2D4F71] text-[#70B1DD] h-fit text-xs p-2 rounded-lg`}>
                                        <p className='md:text-xl md:font-medium'>{item}</p>
                                    </div>))}
                                </div> : skillName && searchResults?.length === 0 ? <div className={`bg-[#2D4F71] text-[#70B1DD] w-fit h-fit text-xs p-2 rounded-lg`}>
                                        <p className='md:text-xl md:font-medium'>{skillName}</p>
                                    </div> : null}
                                <p className='text-xs text-[#B0C1CD] text-center mt-2 underline' onClick={() => setShowCategories(true)}>Or Explore by Category</p>
                            </div>
                        </div>
                    </>}
                    {showCategories && <div className='overflow-y-scroll w-full'>
                        <div className='flex gap-2 items-center'>
                            <FontAwesomeIcon icon={faChevronLeft} className='text-lg' onClick={handleBack}/>
                            <h3 className='text-sm font-semibold'>Couldn’t find what you’re looking for?</h3>
                        </div>
                        <div className='my-4'>
                            <SearchSkills
                                skillName={skillName}
                                setSkillName={setSkillName}
                            />
                        </div>
                        {skillName && searchResults?.length > 0 ? <div className='flex flex-col gap-3'>
                            {searchResults.map((item, index) => (
                            <div key={index} className={`bg-secondary_background w-full flex items-center cursor-pointer md:h-[64px] p-2.5 rounded-lg md:rounded-2xl`}>
                                <div className='w-6 md:w-[32px] h-6 md:h-[32px]'>
                                    <img src={iconList?.[index]} alt="" className='w-full h-full'/>
                                </div>
                                <p className='flex-1 md:text-xl md:font-medium ml-3'>{item}</p>
                            </div>))}
                        </div> : skillName && searchResults?.length === 0 ? <div className={`bg-secondary_background w-full flex items-center cursor-pointer md:h-[64px] p-2.5 rounded-lg md:rounded-2xl`}>
                                <div className='w-6 md:w-[32px] h-6 md:h-[32px]'>
                                    <img src={iconList?.[0]} alt="" className='w-full h-full'/>
                                </div>
                                <p className='flex-1 md:text-xl md:font-medium ml-3'>{skillName}</p>
                            </div> : showSkills ? <div className='flex flex-col gap-3'>
                            {skills?.[skillIndex].map((item, index) => (
                            <div key={index} className={`bg-secondary_background w-full flex items-center cursor-pointer p-2.5 rounded-lg md:rounded-2xl`}>
                                <div className='w-6 md:w-[32px] h-6 md:h-[32px]'>
                                    <img src={item.skill_thumbnail} alt="" className='w-full h-full'/>
                                </div>
                                <p className='flex-1 md:text-xl md:font-medium ml-3'>{item.skill_name}</p>
                            </div>))}
                        </div> : <div className='flex flex-col gap-3'>
                            {courses.map((item, index) => (
                            <div key={index} className={`bg-secondary_background w-full flex items-center cursor-pointer p-2.5 rounded-lg md:rounded-2xl`} onClick={() => {
                                setShowSkills(true)
                                setSkillIndex(index)
                            }}>
                                <div className='w-6 md:w-[32px] h-6 md:h-[32px]'>
                                    <img src={item.image} alt="" className='w-full h-full'/>
                                </div>
                                <p className='flex-1 md:text-xl md:font-medium ml-3'>{item.name}</p>
                                <div className='mr-1'>
                                    <FontAwesomeIcon icon={faChevronRight} className='md:text-lg'/>
                                </div>
                            </div>))}
                        </div>}
                    </div>}
                </div>
            </Dialog>
        </div>
    )
}

export default RelatedSubjectsModal