import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    beginnerPackSelected: false,
    interMediatePackSelected: false,
    advancedPackSelected: false,
    openedFromBeginner: false,
    openedFromInterMediate: false,
    openedFromAdvanced: false
}

const largerComboPackSlice = createSlice({
    name: 'largeComboPack',
    initialState,
    reducers: {
        setBeginnerPackSelected: (state) => {
            if (state.beginnerPackSelected){
                state.beginnerPackSelected = false
            }else{
                state.beginnerPackSelected = true
                state.interMediatePackSelected = false
                state.advancedPackSelected = false
            }
        },
        setInterMediatePackSelected: (state) => {
            if (state.interMediatePackSelected){
                state.interMediatePackSelected = false
            }else{
                state.beginnerPackSelected = false
                state.interMediatePackSelected = true
                state.advancedPackSelected = false
            }
        },
        setAdvancedPackSelected: (state) => {
            if (state.advancedPackSelected){
                state.advancedPackSelected = false
            }else{
                state.beginnerPackSelected = false
                state.interMediatePackSelected = false
                state.advancedPackSelected = true
            }
        },
        resetInitialStateOfPack: () => initialState
    }

})

export const { setBeginnerPackSelected, setInterMediatePackSelected, setAdvancedPackSelected, resetInitialStateOfPack } = largerComboPackSlice.actions
export const selectLargeComboPacks = (state) => state.largerComboPacks
export default largerComboPackSlice.reducer