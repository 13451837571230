import mixpanel from 'mixpanel-browser';
import { MIXPANEL_TOKEN } from './api_config';

mixpanel.init(MIXPANEL_TOKEN, { api_transport: 'sendBeacon' });
let actions = {
    identify: (id) => {
        mixpanel.identify(id);
    },
    alias: (id) => {
        mixpanel.alias(id);
    },
    track: (name, props) => {
        mixpanel.track(name, props);
    },
    track_links: (link_name, props) => {
        mixpanel.track_links(link_name, props, {
            "referrer": document.referrer
        });
    },
    people: {
        set: (props) => {
            mixpanel.people.set(props);
        },
        set_once: (props) => {
            mixpanel.people.set_once(props);
        },
        append: (props) => {
            mixpanel.people.append(props);
        },
        increment: (props) => {
            mixpanel.people.increment(props)
        }
    },
    set_config: (props) => {
        mixpanel.set_config(props);
    },
    register: (props) => {
        mixpanel.register(props);
    },
    register_once: (props) => {
        mixpanel.register_once(props);
    },
};

export const Mixpanel = actions;