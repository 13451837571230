
const Timer = ({ minutes, seconds }) => {
    return (
        <div className='mt-4 md:mt-8'>
            <div className='border-[1.5px] flex items-center bg-[#213E59] flex-col border-dashed border-danger rounded-2xl p-3'>
                <p className='text-sm md:text-xl'>Unlock Now! Your Result Is <span className='font-bold text-danger'>Saved For 15 Mins</span></p>
                <div className='flex mt-1'>
                    <div className='mr-4'>
                        <p className='text-xl font-bold'>00</p>
                        <p className='text-xxs text-white/50'>Hours</p>
                    </div>
                    <p className='text-xl mr-3'>:</p>
                    <div className='mr-3'>
                        <p className='text-xl font-bold'>{minutes.toString().padStart(2, '0')}</p>
                        <p className='text-xxs text-white/50'>Minutes</p>
                    </div> 
                    <p className='text-xl mr-3'>:</p>
                    <div>
                        <p className='text-xl font-bold'>{seconds.toString().padStart(2, '0')}</p>
                        <p className='text-xxs text-white/50'>Seconds</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Timer