import { faArrowRight, faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog } from '@headlessui/react'
import React, { useState } from 'react'
import { ClipLoader } from 'react-spinners'
import { useAddonContext } from '../../context/AddonFeatureContext'

const ShowCancellation = ({ isOpen, setIsOpen, razorpayHandler, setShowAdditionalFeature }) => {

    const [ showLoader, setShowLoader ] = useState(false)
    const { showFeatures, setShowFeatures, skillIsNotSeo } = useAddonContext()
    
    const handleClose = () => {
        setShowAdditionalFeature(true)
        if (showFeatures) setShowFeatures(true)
        setIsOpen(false)
    }

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                as='div'
                className="absolute inset-0 flex flex-col mx-auto text-white"
            >
                <Dialog.Overlay className="fixed inset-0 mx-auto z-10 bg-black opacity-60 "/>
                <Dialog.Title/>
                <Dialog.Description/>
                <div className="bg-[#2D4F71] border-2 border-[#7FC241] inset-0 absolute my-auto py-4 px-3 max-w-[560px] mx-auto md:mx-0 md:left-1/2 md:max-w-[400px] md:-translate-x-1/2 z-50 w-[91%] h-fit text-white rounded-xl">
                    <div className='absolute top-3 right-3 cursor-pointer' onClick={handleClose
                    }>
                        <FontAwesomeIcon icon={faXmark} className='text-2xl font-bold' />
                    </div>
                    <img src="https://ik.imagekit.io/238r9emirjj/LinkdinUsers/man-holding-a-discount-coupon%201.png?updatedAt=1700117306019" width={111} height={126} className='mx-auto md:w-1/5' alt="" />
                    <h2 className='text-2xl md:text-xl font-semibold text-center w-full mt-3 mb-5 md:mb-2'>You’re <span className='text-[#00C1FF]'>just 10 seconds</span> away from getting certified!</h2>
                    <p className='font-medium'>If you leave, you miss out on:</p>
                    <div className='bg-[#14395F] rounded-xl p-3 mt-1.5'>
                        <div className="pb-3 pt-1 flex items-center gap-2 border-b border-[#376796]">
                            <div className='w-5 h-5 flex items-center justify-center bg-[#7FC241] rounded-full'>
                                <FontAwesomeIcon icon={faCheck} color='white' size='lg'/>
                            </div>
                            <p className='text-sm'>Your special <span className='text-[#00C1FF] font-bold'>66% discount</span></p>
                        </div>
                        <div className="py-3 flex items-center gap-2 border-b border-[#376796]">
                            <div className='w-5 h-5 flex items-center justify-center bg-[#7FC241] rounded-full'>
                                <FontAwesomeIcon icon={faCheck} color='white' size='lg'/>
                            </div>
                            <p className='text-sm'><span className='text-[#00C1FF] font-bold'>42% higher chance </span>of CV being shortlisted</p>
                        </div>
                        <div className="pt-3 pb-1 flex items-center gap-2">
                            <div className='w-5 h-5 flex items-center justify-center bg-[#7FC241] rounded-full'>
                                <FontAwesomeIcon icon={faCheck} color='white' size='lg'/>
                            </div>
                            <p className='text-sm'>Standing out from 2 crore other job seekers</p>
                        </div>
                    </div>
                    <p className='text-sm mt-2.5'>Certificate unlocks instantly after payment. <span className='opacity-70'>If not unlocked, score & certificate will be</span> <span className='text-danger'>deleted after 15 minutes.</span></p>
                    <div className='mt-4 bg-[#7FC241] py-2.5 px-2 flex items-center justify-center gap-4 rounded-lg cursor-pointer text-center text-black font-bold' onClick={() => {
                        setShowLoader(true)
                        razorpayHandler(() => setShowLoader(false))
                    }}>
                        {!showLoader ? <>
                            <p>Get Certified Now</p>
                            <FontAwesomeIcon icon={faArrowRight} className='text-lg font-semibold arrow-move'/>
                        </> : <ClipLoader size={20}/>}
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default ShowCancellation