import { useState, useEffect } from "react"
import { Api_url } from '../common/api_url'
import { API_XANO_BASE_URL } from '../config/api_config'
import axios from "axios"

const getModalPage = async (pageParam = 1, options={}) => {
    const response = await axios.get(
        API_XANO_BASE_URL + Api_url.GetOurLearnersWorkAt + `?page=${pageParam}`, options
    )
    return response.data.data
}

const useModalData = (pageNum = 1) => {
    const [usersData, setUsersData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [error, setError] = useState({})
    const [hasNextPage, setHasNextPage] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        setIsError(false)
        setError({})

        const controller = new AbortController()
        const { signal } = controller

        getModalPage(pageNum)
        .then(result => {
            setUsersData((prev) => ([...prev, ...result]))
            setHasNextPage(Boolean(result.length))
            setIsLoading(false)

        }).catch(e => {
            setIsLoading(false)
            if (signal.aborted) return
            setIsError(true)
            setError({message: e.message})
        })

        return () => controller.abort()
    }, [pageNum])

    return {usersData, isLoading, isError, error, hasNextPage}
}

export default useModalData