import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { courses } from '../utilities/constants'
import { axiosAuth } from '../config/axiosInstances';
import { API_XANO_CERTIFIED_BASE_URL } from '../config/api_config';
import { Api_url } from '../common/api_url';

export const fetchTotalRecordsInLeaderboard = createAsyncThunk(
    'fetchTotalRecordsInLeaderboard',
    async ({ certified_user_skill_id }) => {
        try {
            const response = await axiosAuth.get(API_XANO_CERTIFIED_BASE_URL + Api_url.LeaderboardTotalRecords, {
                params: {
                    certified_user_skill_id
                }
            })
            return response.data?.data
        } catch (error) {
            console.log(error.message)
        }
    }
)

export const sendUserCareerdetails = createAsyncThunk(
    'sendUserCareerdetails',
    async ({ certified_user_skill_id, current_status, career_goal, current_job, current_degree, desired_job, selected_skill }) =>{
        try {
            const response = await axiosAuth.post(API_XANO_CERTIFIED_BASE_URL + Api_url.CareerGoals, {
                certified_user_skill_id, current_status, career_goal, current_job, current_degree, desired_job, selected_skill
            })
            return response.data.data
        } catch (error) {
            console.log(error.message)
        }
    }
)

export const sendReferralId = createAsyncThunk(
    'sendReferralId',
    async ({ referral_id, test_bank_id }) => {
        try {
            const response = await axiosAuth.post(API_XANO_CERTIFIED_BASE_URL + Api_url.ReferralId, {
                referral_id, test_bank_id
            })
            return response.data
        } catch (error) {
            console.log(error)
        }
    }
)

export const getFirstTransactionDetails = createAsyncThunk(
    'getFirstTransactionDetails',
    async ({ certified_user_skill_id }) => {
        try {
            const response = await axiosAuth.get(API_XANO_CERTIFIED_BASE_URL + Api_url.TransactionHistory, {
                params: {
                    certified_user_skill_id
                }
            })
            return response.data?.data
        } catch (error) {
            console.log(error.message)
        }
    }
)

const initialState = {
    landingPage: {
        skill_name: "",
        course_name: "",
        utm_source: "",
        utm_medium: ""
    },
    userDetails: {
        user_name: "",
        phone_no: "",
        email: "",
        user_goal: '',
        edited_goal: '',
        professional_experience: "",
        career_goal: '',
        career_degree: '',
        current_job: '',
        skill_to_learn: '',
        referral_id: '',
        test_id: 0,
        userGoalSteps: 0,
        desired_job:''
    },
    certificateList: [],
    noOfcerticatesFromEachCourse: [...Array(courses.length).fill(0)],
    seconds: 0,
    customizedSkillList: [],
    source: '',
    totalRecords: 0,
    relatedJobs: [],
    showAnimation: -1,
    suggestedBundlePurchased: false,
    showProfessionalExperience: false,
    showCareerGoal: false,
    showGoalDetails: false,
    expandCertificate: false,
    certificateDownloaded: false,
    courseLoading: false,
    firstTransactionDetails: {},
    showAddOnsModal: false,
    showCourseModal: false,
    showCategoryModal: false,
    showPlacementModal: false,
    showRelatedSubjectsModal: false,
    videoDetails: {
        url: '',
        title: '',
        subtext: '',
        thumbnail: ''
    }
}

// Redux slice for questions
const otherDetailsSlice = createSlice({
    name: 'otherDetails',
    initialState,
    reducers: {
        setLandingPageDetails: (state, action) => {
            const { skillName, utmSource, courseName, utmMedium } = action.payload
            state.landingPage = {
                skill_name: skillName,
                utm_source: utmSource,
                course_name: courseName,
                utm_medium: utmMedium  
            }
        },
        setUserSkillDetails: (state, action) => {
            state.landingPage.skill_name = action.payload
        },
        setUserName: (state, action) => {
            state.userDetails.user_name = action.payload
        },
        setUserPhoneNo: (state, action) => {
            state.userDetails.phone_no = action.payload
        },
        setUserEmail: (state, action) => {
            state.userDetails.email = action.payload
        },
        setUserCertificateList: (state, action) => {
            state.certificateList = action.payload
        },
        setUserGoal: (state, action) => {
            const { userGoal } = action.payload
            state.userDetails.user_goal = userGoal
        },
        setUserEditedGoal: (state, action) => {
            state.userDetails.edited_goal = action.payload
        },
        setUserDetails: (state, action) => {
            const { name, email, phone_number } = action.payload
            state.userDetails.user_name = name
            state.userDetails.email = email
            state.userDetails.phone_no = phone_number
        },
        setSeconds: (state, action) => {
            state.seconds = action.payload;
        },
        incrementSeconds: state => {
            state.seconds += 1;
        },
        setNoOfcertificatesFromEachCourse: (state, action) => {
            state.noOfcerticatesFromEachCourse = action.payload
        },
        setCustomizedCertificateList: (state, action) => {
            state.customizedSkillList = action.payload
        },
        setUserDetailsFromPostPaidFeature: (state, action) => {
            const { name, email, phone_number, subject_name, goal } = action.payload
            state.userDetails.user_name = name || ''
            state.userDetails.email = email || ''
            state.userDetails.phone_no = phone_number || ''
            state.landingPage.skill_name = subject_name || ''
            state.userDetails.user_goal = goal || ''
        },
        setSource: (state, action) => {
            state.source = action.payload
        },
        setUserProfessionalExperience: (state, action) => {
            state.userDetails.professional_experience = action.payload
        },
        setUserCareerGoal: (state, action) => {
            state.userDetails.career_goal = action.payload
        },
        setUserCareerDegree: (state, action) => {
            state.userDetails.career_degree = action.payload
        },
        setUserCurrentJob: (state, action) => {
            state.userDetails.current_job = action.payload
        },
        setUserSkillToLearn: (state, action) => {
            state.userDetails.skill_to_learn = action.payload
        },
        setUserDesiredJob: (state, action) => {
            state.userDetails.desired_job = action.payload
        },
        setShowAnimation: (state, action) => {
            state.showAnimation = action.payload
        },
        setReferralId: (state, action) => {
            state.userDetails.referral_id = action.payload
        },
        setTestId: (state, action) => {
            state.userDetails.test_id = action.payload
        },
        setSuggestedBundlePurchased: (state, action) => {
            state.suggestedBundlePurchased = action.payload
        },
        setUserGoalSteps: (state, action) => {
            state.userDetails.userGoalSteps = action.payload
        },
        setShowProfessionalExperience: (state, action) => {
            state.showProfessionalExperience = action.payload
        },
        setShowCareerGoal: (state, action) => {
            state.showCareerGoal = action.payload
        },
        setShowGoalDetails: (state, action) => {
            state.showGoalDetails = action.payload
        },
        setExpandCertificate: (state, action) => {
            state.expandCertificate = action.payload
        },
        setCertificateDownloaded: (state, action) => {
            state.certificateDownloaded = action.payload
        },
        setCourseLoading: (state, action) => {
            state.courseLoading = action.payload
        },
        setShowAddOnsModal: (state, action) => {
            state.showAddOnsModal = action.payload
        },
        setShowCourseModal: (state, action) => {
            state.showCourseModal = action.payload
        },
        setShowCategoryModal: (state, action) => {
            state.showCategoryModal = action.payload
        },
        setShowPlacementModal: (state, action) => {
            state.showPlacementModal = action.payload
        },
        setShowRelatedSubjectsModal: (state, action) => {
            state.showRelatedSubjectsModal = action.payload
        },
        setVideoDetails: (state, action) => {
            state.videoDetails = action.payload
        },
        resetDetails: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTotalRecordsInLeaderboard.fulfilled, (state, action) => {
            state.totalRecords = action.payload
        })
        builder.addCase(getFirstTransactionDetails.fulfilled, (state, action) => {
            state.firstTransactionDetails = action.payload
        })
    },
})


export const { setLandingPageDetails, setUserSkillDetails, setUserName, setUserPhoneNo, setUserEmail, setUserCertificateList, setUserGoal, setUserEditedGoal, setUserDetails, setSeconds, incrementSeconds, setNoOfcertificatesFromEachCourse, setCustomizedCertificateList, setRequirementForAnalysis, setUserDetailsFromPostPaidFeature, setSource, resetDetails, setUserProfessionalExperience, setUserCareerGoal, setUserCareerDegree, setUserCurrentJob, setUserSkillToLearn, setReferralId, setTestId, setShowAnimation, setSuggestedBundlePurchased, setUserGoalSteps, setShowProfessionalExperience, setShowCareerGoal, setShowGoalDetails, setExpandCertificate, setCertificateDownloaded, setCourseLoading, setUserDesiredJob, setShowAddOnsModal, setShowCourseModal, setShowCategoryModal, setShowPlacementModal, setShowRelatedSubjectsModal, setVideoDetails } = otherDetailsSlice.actions
export const selectLandingPageDetails = (state) => state.otherDetails.landingPage
export const selectUserDetails = (state) => state.otherDetails.userDetails
export const selectCertificateList = (state) => state.otherDetails.certificateList
export const selectSeconds = (state) => state.otherDetails.seconds
export const selectNoOfCertificatesFromEachCourse = (state) => state.otherDetails.noOfcerticatesFromEachCourse
export const selectCustomizedSkillList = (state) => state.otherDetails.customizedSkillList
export const selectSource = (state) => state.otherDetails.source
export const selectTotalRecords = (state) => state.otherDetails.totalRecords
export const selectShowAnimation = (state) => state.otherDetails.showAnimation
export const selectSuggestedBundleStatus = (state) => state.otherDetails.suggestedBundlePurchased
export const selectShowProfessionalExperience = (state) => state.otherDetails.showProfessionalExperience
export const selectShowCareerGoal = (state) => state.otherDetails.showCareerGoal
export const selectShowGoalDetails = (state) => state.otherDetails.showGoalDetails
export const selectExpandCertificate = (state) => state.otherDetails.expandCertificate
export const selectCertificateDownloaded = (state) => state.otherDetails.certificateDownloaded
export const selectCourseLoading = (state) => state.otherDetails.courseLoading
export const selectFirstTransactionDetails = (state) => state.otherDetails.firstTransactionDetails
export const selectShowAddOnsModal = (state) => state.otherDetails.showAddOnsModal
export const selectShowCourseModal = (state) => state.otherDetails.showCourseModal
export const selectShowCategoryModal = (state) => state.otherDetails.showCategoryModal
export const selectShowPlacementModal = (state) => state.otherDetails.showPlacementModal
export const selectShowRelatedSubjectsModal = (state) => state.otherDetails.showRelatedSubjectsModal
export const selectVideoDetails = (state) => state.otherDetails.videoDetails
export default otherDetailsSlice.reducer;