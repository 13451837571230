import React, { useEffect, useMemo } from 'react'
import { useAddonContext } from '../../context/AddonFeatureContext'
import { selectPriceChart } from '../../redux/priceChartSlice'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { Mixpanel } from '../../config/Mixpanel'
import constants from '../../utilities/constants'
import { selectUserDetails, setShowRelatedSubjectsModal } from '../../redux/otherDetailsSlice'

const CardBundles = ({ minutes, seconds, oneCertificateButton }) => {

    const priceChart = useSelector(selectPriceChart)
    const userDetails = useSelector(selectUserDetails)

    const email = userDetails?.email

    const { threeCertificates, fiveCertificates, sevenCertificates, handleCertificateSelection, handleForward, nothingSelected, skillIsBusinessManagement } = useAddonContext()

    const dispatch = useDispatch()

    useEffect(() => {
        Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_BUNDLE_SELECTION_PAGE_LOADED)
    }, [])

    const prices = useMemo(() => [
        {
            id: 1,
            certificates: 3,
            discountedPrice: skillIsBusinessManagement ? priceChart?.[37]?.price : priceChart?.[2]?.price,
            normalPrice: skillIsBusinessManagement ? priceChart?.[37]?.original_price : priceChart?.[2]?.original_price,
            tag: '48% Higher Chance of Standing Out'
        },
        {
            id: 2,
            certificates: 5,
            discountedPrice: skillIsBusinessManagement ? priceChart?.[38]?.price : priceChart?.[10]?.price,
            normalPrice: skillIsBusinessManagement ? priceChart?.[38]?.original_price : priceChart?.[10]?.original_price,
            tag: '80% Higher Chance of Standing Out'
        }
    ], [priceChart, skillIsBusinessManagement])

    const sevenCertPrice = useMemo(() => {
        return {
            certificates: 7,
            discountedPrice: skillIsBusinessManagement ? priceChart?.[39]?.price : priceChart?.[11]?.price,
            normalPrice: skillIsBusinessManagement ? priceChart?.[39]?.original_price : priceChart?.[11]?.original_price,
            tag: '2X More Likely To Be Noticed By Top MNCs & Unicorns',
            linkedIn: '64% More Replies From Employers on LinkedIn'
        }
    }, [priceChart, skillIsBusinessManagement])

    const handleViewPossibleSubjectModal = (e) => {
        e.stopPropagation()
        dispatch(setShowRelatedSubjectsModal(true))
    }

    return (
        <>
            <div className='grid grid-cols-2 gap-2.5 text-white'>
                {prices.map((item, index) => {
                    const selected = (threeCertificates && index === 0 ) || (fiveCertificates && index === 1)
                    return (<div key={index} className={`relative flex flex-col bg-secondary_background rounded-2xl p-3 border-[1.5px] border-[#316EAB] cursor-pointer transition-transform h-fit ease-in-out duration-300 hover:scale-105`} onClick={() => handleCertificateSelection(index + 1)}>
                        <div className={`absolute top-3 left-3 ${selected ? 'bg-secondary-text' : 'glow-3'} rounded-[2px] w-[18px] h-[18px] border border-secondary-text shadow-checkBox z-10 flex items-center justify-center`}>
                            {selected && <FontAwesomeIcon icon={faCheck} className='font-semibold'/>}
                        </div>
                        <div className='absolute top-2 right-2 cursor-pointer' onClick={handleViewPossibleSubjectModal}>
                            <img src="https://ik.imagekit.io/238r9emirjj/Info.png?updatedAt=1704268543580" width={18} height={18} alt="" />
                        </div>
                        <div className='flex flex-col items-center'>
                            <h1 className='text-4xl text-span_color font-bold'>{item.certificates}</h1>
                            <p className=' leading-[130%] text-center font-semibold'>more certificates</p>
                            <p className='leading-[130%] font-semibold'>for <span className='text-secondary-text'>₹{item.discountedPrice}</span> <span className='text-danger text-xs'>₹</span><span className='text-danger line-through text-xs'>{item.normalPrice}</span></p>
                        </div>
                        <div className='pt-2 mt-1 border-t border-[#395C7E] flex items-start gap-2 justify-center'>
                            <img src="https://ik.imagekit.io/238r9emirjj/certified%20app/carbon_location-company.png?updatedAt=1709875083942" width={20} height={20} alt="" />
                            <p className='text-xs flex-1 leading-[130%] text-[#B0C1CD]'>{item.tag}</p>
                        </div>
                    </div>)
                })}
                <div className='col-span-2 relative w-full h-fit flex items-center gap-2 bg-secondary_background rounded-2xl p-3 border-[1.5px] border-[#316EAB] cursor-pointer transition-transform ease-in-out duration-300 hover:scale-[1.03]' onClick={() => handleCertificateSelection(3)}>
                    <div className={`absolute top-3 left-3 ${sevenCertificates ? 'bg-secondary-text' : 'glow-3'} rounded-[2px] w-[18px] h-[18px] border border-secondary-text shadow-checkBox z-10 flex items-center justify-center`}>
                        {sevenCertificates && <FontAwesomeIcon icon={faCheck} className='font-semibold'/>}
                    </div>
                    <div className='flex-[0.8] flex flex-col items-center'>
                        <h1 className='text-4xl text-span_color font-bold'>{sevenCertPrice.certificates}</h1>
                        <p className='leading-[130%] text-center font-semibold'>more certificates</p>
                        <p className='leading-[130%] font-semibold'>for <span className='text-secondary-text'>₹{sevenCertPrice.discountedPrice}</span> <span className='text-danger text-xs'>₹</span><span className='text-danger line-through text-xs'>{sevenCertPrice.normalPrice}</span></p>
                    </div>
                    <div className='flex-1 flex items-start gap-1'>
                        <div className='flex flex-col justify-center flex-1'>
                            <div className='flex gap-2 items-center pb-2 border-b border-[#395C7E]'>
                                <img src="https://ik.imagekit.io/238r9emirjj/certified%20app/carbon_location-company.png?updatedAt=1709875083942" width={20} height={20} alt="" />
                                <p className='text-xs flex-1 leading-[130%] text-[#B0C1CD]'>{sevenCertPrice.tag}</p>
                            </div>
                            <div className='flex gap-2 items-center pt-2'>
                                <img src="https://ik.imagekit.io/238r9emirjj/certified%20app/devicon_linkedin.png?updatedAt=1709878343522" width={20} height={20} alt="" />
                                <p className='text-xs flex-1 leading-[130%] text-[#B0C1CD]'>{sevenCertPrice.linkedIn}</p>
                            </div>
                        </div>
                        <img src="https://ik.imagekit.io/238r9emirjj/Info.png?updatedAt=1704268543580" width={18} height={18} alt="" className='-mt-1.5 -mr-1 cursor-pointer' onClick={handleViewPossibleSubjectModal} />
                    </div>
                </div>
            </div>
            <p className='my-2 text-center text-[#B0C1CD]'>or</p>
            <div className={`grounded-radiants ${nothingSelected ? 'cursor-pointer' : 'opacity-50'} h-[50px] text-center`} onClick={nothingSelected ? () =>  {
                Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_CERTIFICATES_CONTINUE_WITH_1_CERTIFICATE, {
                    distinct_id: email
                })
                handleForward()
            } : null}>
                <div className={`inside-div bg-[#2D4F71] flex items-center text-center justify-center`}>
                    <p className='text-xs-xxs text-[#B0C1CD] px-3'>{oneCertificateButton}</p>
                </div>
            </div>
            <div className='flex justify-between gap-1 mt-2'>
                <p className='text-sm underline cursor-pointer' onClick={() => {
                    Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_BUNDLE_SELECTION_PAGE_SUGGESTED_SKILL_MODAL)
                    dispatch(setShowRelatedSubjectsModal(true))
                }}>(View Possible Subjects)</p>
                <div className='flex items-center text-[10px] gap-2'>
                    <p className='font-semibold md:text-sm'>{minutes === 0 && seconds === 0 ? "Time Up. Unlock Now!" : "Discount Ends In:"}</p>
                    <p className='text-danger w-[59px] md:text-xs md:w-[70px] inline-flex justify-center text-center font-extrabold px-1 py-0.5 border-2 border-danger rounded'>{minutes.toString().padStart(2, '0')}M:{seconds.toString().padStart(2, '0')}S</p>
                </div>
            </div>
        </>
    )
}

export default CardBundles