import { useEffect, useState } from "react";

const useDebounce = (value, delay=300) => {
    
    const [debouncedValue, setDebouncedValue] = useState(value)
    const [isTyping, setIsTyping] = useState(false)

    useEffect(()=> {

        const id = setTimeout(() => {
            setDebouncedValue(value)
            setIsTyping(true)
        }, delay)
        
        return () => {
            setIsTyping(false)
            clearTimeout(id)
        }
    }, [value, delay])
    
    return { debouncedValue, isTyping}
}

export default useDebounce