import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API_XANO_CERTIFIED_BASE_URL } from '../config/api_config'
import { Api_url } from '../common/api_url'
import axios from 'axios'
import { axiosAuth } from '../config/axiosInstances'


export const fetchNewMcquestions = createAsyncThunk(
    'newMcquestions',
    async ({ certified_user_skill_id }) => {
        const maxTries = 3
        let tries = 1
        while (tries < maxTries) {
            try {
                const response = await axios.post(API_XANO_CERTIFIED_BASE_URL + Api_url.GenerateMCQuestions, {
                    certified_user_skill_id
                })
                return response.data?.data
            } catch (error) {
                console.log(error.message)
                tries += 1
                if (tries === maxTries){
                    throw error
                }
            }
        }
    }
)

export const sendUserSkill = createAsyncThunk(
    'sendUserSkill',
    async ({ subject_name, utm_source, utm_medium, utm_campaign }) => {
        try {
            const response = await axios.post(API_XANO_CERTIFIED_BASE_URL + Api_url.SendUserSkill, {
                subject_name,
                utm_object: {
                    utm_source,
                    utm_medium,
                    utm_campaign
                }
            })
            return response.data?.data
        } catch (error) {
            console.log(error.message)
            throw error
        }
    }
)

export const saveUserQuizResponse = createAsyncThunk(
    'saveUserQuizResponse',
    async ({ certified_user_skill_quiz_id, quiz_attempt_object, quiz_completion_time_in_seconds, quiz_score }) => {
        try {
            const response = await axios.post(API_XANO_CERTIFIED_BASE_URL + Api_url.SaveUserResponse, {
                certified_user_skill_quiz_id, quiz_attempt_object, quiz_completion_time_in_seconds, quiz_score
            })

            return response.data?.data
        } catch (error) {
            throw error
        }
    }
)

export const dispatchUserGoal = createAsyncThunk(
    'dispatchUserGoal',
    async ({ certified_user_skill_id, user_goal }) => {
        try {
            const response = await axiosAuth.post(API_XANO_CERTIFIED_BASE_URL + Api_url.UpdateUserGoal, { certified_user_skill_id, user_goal })
            return response.data?.data
        } catch (error) {
            console.log(error)
            throw error
        }
    }
)

export const claimSkillCertificate = createAsyncThunk(
    'claimSkillCertificate',
    async ({ certified_user_skill_id }) => {
        try {
            const response = await axiosAuth.post(API_XANO_CERTIFIED_BASE_URL + Api_url.ClaimCertificate, {
                certified_user_skill_id
            })
            return response.data?.data
        } catch (error) {
            console.log(error.message)
        }
    }
)

export const fetchMCQAnalysis = createAsyncThunk(
    'mcqAnalysis/fetchMCQAnalysis',
    async ({ certified_user_skill_quiz_id }) => {
        const maxRetries = 2;
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosAuth.get(API_XANO_CERTIFIED_BASE_URL + Api_url.UserQuizAnalysis, {
                    params: {
                        certified_user_skill_quiz_id
                    }
                })
                return response.data?.data

            } catch (e) {
                console.log(e.message)
                retries += 1;
                if (retries === maxRetries) throw e
            }
        }
    }
)

export const getUserFullDetails = createAsyncThunk(
    'getUserFullDetails',
    async ({ certified_user_skill_id }) => {
        try {
            const response = await axiosAuth.get(API_XANO_CERTIFIED_BASE_URL + Api_url.GetUserFullDetails, {
                params: {
                    certified_user_skill_id
                }
            })
            return response.data?.data
        } catch (error) {
            console.log(error.message)
        }
    }
)

const initialState = {
    mcqQuestions: {},
    typeformData: {},
    userResponse: {},
    questions: [],
    easyQuestionIndex: 0,
    mediumQuestionIndex: 0,
    hardQuestionIndex: 0,
    currentLevel: 'easy',
    currentStreak: 0
}

const mcqSlice = createSlice({
    name: 'mcquestions',
    initialState,
    reducers: {
        setQuestions: (state, action) => {
            state.questions.push(action.payload)
        },
        setQuizScore: (state, action) => {
            state.mcqQuestions = {
                ...state.mcqQuestions,
                quiz_score: action.payload
            }
        },
        setEasyQuestionIndex: (state) => {
            state.easyQuestionIndex += 1
        },
        setMediumQuestionIndex: (state) => {
            state.mediumQuestionIndex += 1
        },
        setHardQuestionIndex: (state) => {
            state.hardQuestionIndex += 1
        },
        setCurrentLevel: (state, action) => {
            state.currentLevel = action.payload
        },
        setCurrentStreak: (state, action) => {
            state.currentStreak = action.payload
        },
        resetQuestions: () => initialState,
        setPaidStatus: (state) => {
            state.typeformData = {
                ...state.typeformData,
                is_paid: true
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchNewMcquestions.fulfilled, (state, action) => {
            state.mcqQuestions = action.payload
        })
        builder.addCase(sendUserSkill.fulfilled, (state, action) => {
            state.typeformData = action.payload
        })
        builder.addCase(saveUserQuizResponse.fulfilled, (state, action) => {
            state.userResponse = action.payload
        })
        builder.addCase(dispatchUserGoal.fulfilled, (state, action) => {
            state.typeformData = action.payload
        })
        builder.addCase(claimSkillCertificate.fulfilled, (state, action) => {
            state.typeformData = action.payload
        })
        builder.addCase(fetchMCQAnalysis.fulfilled, (state, action) => {
            state.mcqQuestions = {
                ...state.mcqQuestions,
                quiz_analysis: action.payload
            }
        })
        builder.addCase(getUserFullDetails.fulfilled, (state, action) => {
            if (action.payload){
                const { beta_user_id, created_at, goal, id, is_paid, purchase_type, quiz_details, quiz_status,subject_name, updated_at } = action.payload
                state.typeformData = { beta_user_id, created_at, goal, id, is_paid, purchase_type, quiz_status, subject_name, updated_at }
                state.mcqQuestions = quiz_details
            }
        })
    }
})

export const { resetQuestions, setQuizScore, setPaidStatus, setQuestions, setEasyQuestionIndex, setMediumQuestionIndex, setHardQuestionIndex, setCurrentStreak, setCurrentLevel } = mcqSlice.actions
export const selectMCQDetails = (state) => state.mcquestions.mcqQuestions
export const selectMCQ = (state) => state.mcquestions.mcqQuestions?.quiz_question_answer
export const selectTypeformData = (state) => state.mcquestions.typeformData
export const selectMcqAnalysis = (state) => state.mcquestions.mcqQuestions?.quiz_analysis
export const selectQuestions = (state) => state.mcquestions.questions
export const selectEasyQuestionIndex = (state) => state.mcquestions.easyQuestionIndex
export const selectMediumQuestionIndex = (state) => state.mcquestions.mediumQuestionIndex
export const selectHardQuestionIndex = (state) => state.mcquestions.hardQuestionIndex
export const selectCurrentLevel = (state) => state.mcquestions.currentLevel
export const selectCurrentStreak = (state) => state.mcquestions.currentStreak
export default mcqSlice.reducer