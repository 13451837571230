import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useWindowSize from '../../hooks/useWindowSize'
import { Mixpanel } from '../../config/Mixpanel'
import constants, { courses } from '../../utilities/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { resetQuestions, sendUserSkill } from '../../redux/mcqSlice'
import { setLandingPageDetails, resetDetails, setSource, selectLandingPageDetails, setUserDetails, setReferralId, setTestId, setUserSkillDetails, setShowAnimation } from '../../redux/otherDetailsSlice'
import { resetAnswers } from '../../redux/answersSlice'
import { resetCourseContent } from '../../redux/personaliseCourseSyllabusSlice'
import { resetPostPaymentSlice, resetPrepaymentDetails } from '../../redux/postPaymentSlice'
import { fetchInfluencerDetail, resetInfluencerData } from '../../redux/influencersDataSlice'
import { getUserDetails, setAuthToken } from '../../redux/authenticationSlice';
import { resetCourseSyllabus } from '../../redux/newPostPaymentSlice';
import { resetInitialState } from '../../redux/multiSkillSelectionSlice';
import { resetResults } from '../../redux/searchSlice';
import { resetInitialStateOfPack } from '../../redux/largerComboPackSlice';
import { toast } from 'react-toastify';
import { resetTimer } from '../../redux/timerSlice';
import { dayResetTimer } from '../../redux/dayTimerSlice';


const LandingPage = () => {

    const landingPageDetails = useSelector(selectLandingPageDetails)
    
    const userSkillName = landingPageDetails?.skill_name
    const utmMedium = landingPageDetails?.utm_medium 
    const utmSource = landingPageDetails?.utm_source

    const [isClicked, setIsClicked] = useState(false);
    const [courseName, setCourseName] = useState("")
    const [skillName, setSkillName] = useState("")
    const [randomDigit, setRandomDigit] = useState(0)
    const [navigateToSkill, setNavigateToSkill] = useState(false)
    const [navigateToCourse, setNavigateToCourse] = useState(false)

    const { height } = useWindowSize()
    const { skill_name, utm_source } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const location = useLocation()
    
    const params = new URLSearchParams(location.search)
    const utm_source1 = params.get('utm_source')
    const utm_medium = params.get('utm_medium')
    const source = params.get('source')
    const jwt_token = params.get('uuid')
    const referral_id = params.get('referral_id')
    const test_id = params.get('test_id')
    const skill_name_from_url = params.get('skill_name')

    const handleClick = () => {
        setIsClicked(true);
        // Reset the animation class after a delay
        setTimeout(() => {
        setIsClicked(false);
        }, 1000);
    };
    
    function capitalizeFirstLetter(sentence = '') {
        // Split the sentence into an array of words
        const words = sentence.split(' ');
      
        // Capitalize the first letter of each word
        const capitalizedWords = words.map(word => {
          // Ensure that the word is not an empty string
          if (word.length > 0) {
            // Capitalize the first letter and concatenate with the rest of the word
            return word.charAt(0).toUpperCase() + word.slice(1);
          }
          // If the word is an empty string, return it as is
          return word;
        });
      
        // Join the capitalized words back into a sentence
        const capitalizedSentence = capitalizedWords.join(' ');
      
        return capitalizedSentence;
    }

    const fetchData = useCallback(async (token) => {
        try {
            const response = await dispatch(getUserDetails(token))
            const { name, email, phone_number } = response.payload
            dispatch(setUserDetails({ name, email, phone_number }))
        } catch (error) {
            console.log(error.message)
        }
    }, [dispatch])
    
    useEffect(() => {

        Mixpanel.track(constants.Mixpanel.LANDING_PAGE)
        dispatch(setAuthToken(''))
        dispatch(resetInfluencerData())
        dispatch(resetQuestions())
        dispatch(resetDetails())
        dispatch(resetAnswers())
        dispatch(resetCourseContent())
        dispatch(resetPrepaymentDetails())
        dispatch(setShowAnimation(-1))
        dispatch(resetCourseSyllabus())
        dispatch(resetInitialState())
        dispatch(resetResults())
        dispatch(resetInitialStateOfPack())
        dispatch(resetPostPaymentSlice())
        dispatch(resetTimer())
        dispatch(dayResetTimer())
        if (source === 'academy'){
            dispatch(setSource(source))
            dispatch(fetchInfluencerDetail(utm_source1))
        }
        if (jwt_token){
            dispatch(setAuthToken(jwt_token))
            fetchData(jwt_token)
        }
        if ((utm_source && !utm_source?.includes("=")) || (skill_name && !skill_name?.includes("="))){
            navigate('/error')
        }
        if (referral_id){
            dispatch(setReferralId(referral_id))
        }
        if (test_id){
            dispatch(setTestId(test_id))
        }
        let utmSource = utm_source?.split("=")
        let skill = skill_name?.split("=")
        
        if (!skill_name){
            dispatch(setLandingPageDetails({ skillName: '', utmSource: utmSource ? utmSource[1] : utm_source1 ? utm_source1 : '',  courseName: '', utmMedium: utm_medium || ''}))
            setNavigateToCourse(true)
        }else if (courses?.filter((item) => item.course_slug === skill?.[1])[0]){
            const courseDetail = courses?.filter((item) => item.course_slug === skill?.[1])[0]
            Mixpanel.people.set({
                "courseName": courseDetail?.name
            })
            dispatch(setLandingPageDetails({ skillName: '', utmSource: utmSource ? utmSource[1] : utm_source1 ? utm_source1 : '',  courseName: courseDetail?.name, utmMedium: utm_medium || ''}))
            setCourseName(courseDetail?.name)
            setNavigateToSkill(true)
        } else {
            Mixpanel.people.set({
                "skillName": capitalizeFirstLetter(skill?.[1])
            })
            dispatch(setLandingPageDetails({ skillName: capitalizeFirstLetter(skill?.[1]), utmSource: utmSource ? utmSource[1] : utm_source1 ? utm_source1 : '', courseName: '', utmMedium: utm_medium || '' }))
            setSkillName(capitalizeFirstLetter(skill?.[1]))
        }
        if (skill_name_from_url){
            setSkillName(skill_name_from_url)
            dispatch(setUserSkillDetails(skill_name_from_url))
            setNavigateToCourse(false)
        }
        const randomDigitGenerate = () => {
            const min = 7000;
            const max = 10000;
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }
        setRandomDigit(randomDigitGenerate)
    }, [dispatch, jwt_token, navigate, skill_name, utm_source, utm_source1, source, utm_medium, fetchData, referral_id, test_id, skill_name_from_url])

    
    const handleSubmit = async () => {
        try {
            if (navigateToCourse) {
                Mixpanel.track(constants.Mixpanel.LANDING_PAGE_BUTTON_CLICKED)
                navigate('/1/course-page')
            }
            else if (navigateToSkill){
                Mixpanel.track(constants.Mixpanel.LANDING_PAGE_BUTTON_CLICKED)
                navigate('/2/skill-page')
                
            }else {
                Mixpanel.track(constants.Mixpanel.LANDING_PAGE_BUTTON_CLICKED)
                await  dispatch(sendUserSkill({ subject_name: userSkillName, utm_source: utmSource, utm_medium: utmMedium })).unwrap()
                navigate('/onboarding')
            }
        } catch (error) {
            toast.error('Oops, that request failed. Please try again!')
        }
        
    }
    
    return (
        
        <div className='text-white max-w-[1440px] flex-1 flex flex-col lg:flex-row lg:justify-between lg:gap-4 lg:px-6 lg:items-center mx-auto'>
            <div className='flex-1 h-full flex flex-col md:justify-center'>
                <h1 className={`text-[28px] lg:hidden ${height <= 740 ? '' : 'mt-12 md:mt-0'} text-center leading-[34px]`}><span className='font-bold text-span_color'>Unlock This Certificate,</span><span className='italic'> Recognised By 1100+ Top Companies To</span><span className='font-bold text-span_color'> Prove Your {courseName ? courseName : skillName ? skillName : ''} Skills.</span></h1>
                <div className=''>
                    <div className= {`${height <= 720 ? 'mt-4' : 'mt-8'} w-full mx-auto md:w-[90%]`} style={{
                        boxShadow: '0px 0px 12px 0px #FFCC17B2'
                    }}>
                        <img src="https://ik.imagekit.io/238r9emirjj/certifiedOptimisation/certified-landing-page.webp?updatedAt=1720514693628" alt="" className='w-full h-auto object-cover'/>
                    </div>
                    <div className='bg-secondary_background py-3 rounded-xl mt-4 w-full mx-auto md:w-[90%]'>
                        <img src="https://ik.imagekit.io/238r9emirjj/AI_Syllabus_generator/Group_8594.png?updatedAt=1687438472209" alt="" className='w-full h-auto object-cover' />
                    </div>
                </div>
            </div>
            <div className='hidden w-full lg:flex lg:flex-col flex-1 items-center'>
                <h1 className={`text-[44px] hidden lg:block leading-[50px] text-center`}><span className='font-bold text-span_color'>Unlock This Certificate,</span><span className='italic md:not-italic'> Recognised By 1100+ Top Companies To</span><span className='font-bold text-span_color'> Prove Your {courseName ? courseName : skillName ? skillName : ''} Skills.</span></h1>
                <div className='flex flex-col items-center mt-10'>
                    <div className='bg-[#7FC241] w-full mx-auto cursor-pointer flex text-black justify-center items-center py-3 rounded-xl px-3' onClick={() => {
                        handleClick()
                        handleSubmit()
                    }}>
                        <p className='font-semibold'>Get Certified</p>
                        <FontAwesomeIcon icon={faArrowRight} className='text-xl ml-4 arrow-move'/>
                    </div>
                    <p className='text-sm text-white/70 mx-auto mt-1'>{randomDigit} users got certified this week in <span className='font-medium text-sm text-white ml-1 mt-0.5'> just 5 mins!</span></p>
                </div>
            </div>
            <div className='fixed lg:hidden flex flex-col justify-between items-center bottom-0 w-full left-0 z-50 md:left-1/2 md:-translate-x-[50%] pb-1.5 pt-3 px-2 bg-[#26588B] md:w-full'>
                <div className={`bg-[#7FC241] btn--glow sticky bottom-0 text-black flex justify-center items-center  rounded-xl ${height <= 720 ? 'py-2' : 'py-3.5'} px-4 w-[75%] cursor-pointer ${isClicked ? 'blink' : ''}`} onClick={() => {
                    handleClick()
                    handleSubmit()
                }}>
                    <p className='font-semibold md:text-[21px]'>Get Certified</p>
                    <FontAwesomeIcon icon={faArrowRight} className='text-xl ml-4 arrow-move'/>
                </div>
                <div className=''>
                    <p className='mt-0.5 text-sm md:text-lg text-white/70 text-center'>{randomDigit} users got certified this week in <span className='font-medium text-white text-sm'> just 5 mins!</span></p>
                </div>
            </div>
            <div className='hidden md:block lg:hidden h-[74px] w-full'></div>
            {height <= 740 && <div className='h-[200px]'></div>}
        </div>
        
    )
}

export default LandingPage