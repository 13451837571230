import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    skill_name: '',
    skill_level: '',
    skill_level_description: '',
    analysisPageButton: false,
    utm_source: ''
}
const personalisedCourseDetailsSlice = createSlice({
    name: 'personalisedCourseDetails',
    initialState,
    reducers: {
        setPersonaliseSkillName: (state, action) => {
            state.skill_name = action.payload
        },
        setPersonaliseSkill: (state, action) => {
            state.skill_level = action.payload.skill
            state.skill_level_description = action.payload.description
        },
        setUtmAndSkill: (state, action) => {
            const { skillName, utm_source } = action.payload
            state.skill_name = skillName
            state.utm_source = utm_source
        },
        setAnalysisPageButtonClicked: (state, action) => {
            state.analysisPageButton = action.payload
        }
    }
})

export const { setPersonaliseSkillName, setPersonaliseSkill, setAnalysisPageButtonClicked, setUtmAndSkill } = personalisedCourseDetailsSlice.actions
export const selectPersonaliseCourseSkill = (state) => state.personalisedCourseDetail.skill_name
export const selectPersonaliseCourseSkillLevelDescription = (state) => state.personalisedCourseDetail.skill_level_description
export const selectPersonalisedCourseSkillLevel = (state) => state.personalisedCourseDetail.skill_level
export const selectUtmSource = (state) => state.personalisedCourseDetail.utm_source
export const selectAnalysisPageButtonClicked  = (state) => state.personalisedCourseDetail.analysisPageButton

export default personalisedCourseDetailsSlice.reducer