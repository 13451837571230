import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog } from '@headlessui/react'
import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import Slider from 'react-slick'


const settings = {
    dots: false,
    slidesToShow: 2,
    rows: 1,
    speed: 10000,
    autoplay: true,
    autoplaySpeed: 3000,
    easing: 'linear',
    cssEase: "linear",
    infinite: true,
    arrows: true,
    responsive: [
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1.5,
                infinite: true,
                dots: false,
                arrows: false,
            swipeToSlide: true,
            },
        },
]}

const AchievementSection2 = ({achievements}) => {
    
    const [modalEnabled , setModalEnabled] = useState({is_show : false , achievement:{ name: '', description: '', from: '', to: '', thumbNail: '', videoUrl: '', thumbNail2: ''}})
    
    return (
        <div className='w-full'>
            <Slider {...settings}>
                {achievements?.length > 0 && achievements?.map((item, index) => (<div key={index} className='w-full'>
                    <div className="flex relative w-[96%] h-full flex-col items-center justify-center font-light cursor-pointer md:py-2" onClick={()=> {
                        setModalEnabled(()=> ({is_show : true , achievement : item}))
                    }}>
                        <img src={item?.thumbNail} alt='' className='w-full'></img>
                        <div className='absolute z-20 top-3 left-3 flex items-center gap-0.5'>
                            <h2 className='font-bold text-white/80'>{item?.name}</h2>
                            <img src="https://ik.imagekit.io/238r9emirjj/Post%20Payment/Info.png?updatedAt=1706103676989" alt="" className='inline w-5 h-5 ml-0.5'/>
                        </div>
                        <div className='flex bottom-3 justify-evenly items-center text-sm font-medium absolute w-full px-2 gap-2'>
                            <div className='leading-4 flex-1'>
                                <p>{item.from}</p>
                            </div>
                            <img src="https://ik.imagekit.io/238r9emirjj/Post%20Payment/Arrow.png?updatedAt=1704522242977" width={26} height={26} alt="" className='w-auto h-[26px]'/>
                            <div className='flex-1 text-end'>
                                <p>{item.to}</p>
                            </div>
                        </div>
                    </div>
                </div>))}
            </Slider>
            {modalEnabled?.is_show &&   <Dialog open={modalEnabled?.is_show} onClose={() => {
                setModalEnabled((prev)=> ({...prev , is_show : false}))}}
                as="div"
                style={{ zIndex: '9999999999' }}
                className="fixed inset-0 z-10 flex md:justify-start justify-center flex-col items-center">
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-70 z-10" />
                <Dialog.Title />
                <Dialog.Description />
                <div className='bg-[#2D4F71] z-50 w-[90%] max-w-lg rounded-2xl text-white max-h-[650px] overflow-y-scroll relative'>
                    <div className='absolute right-3 top-3 cursor-pointer' onClick={() => setModalEnabled((prev)=> ({...prev , is_show : false}))}>
                        <FontAwesomeIcon icon={faXmark} className='text-2xl' />
                    </div>
                    <div className='z-[9999]'>
                        <ReactPlayer 
                            url={modalEnabled?.achievement?.videoUrl} 
                            width='100%'
                            height={'229px'}
                        />
                    </div>
                    <div className='p-3'>
                        <div className='flex items-center gap-2'>
                            <div className='w-9 h-9'>
                                <img src={modalEnabled?.achievement?.thumbNail} alt="" className='w-full h-full rounded-full object-cover'/>
                            </div>
                            <div>
                                <h3 className='font-semibold'>{modalEnabled?.achievement?.name}</h3>
                                <div className='flex gap-2 items-center'>
                                    <img src="https://ik.imagekit.io/238r9emirjj/Post%20Payment/Vector.png?updatedAt=1706102191445" width={15} height={15} className='rounded-full' alt="" />
                                    <p className='text-xs text-white/60'>LearnTube Max User</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-evenly items-center text-lg font-medium w-full px-2 gap-2 mt-3'>
                            <div className='leading-5 flex-1'>
                                <p>{modalEnabled?.achievement?.from}</p>
                            </div>
                            <img src="https://ik.imagekit.io/238r9emirjj/Post%20Payment/Arrow.png?updatedAt=1704522242977" width={26} height={26} alt="" className='w-auto h-[26px]' />
                            <div className='flex-1 text-end leading-5'>
                                <p>{modalEnabled?.achievement?.to}</p>
                            </div>
                        </div>
                        <div className='flex flex-col gap-2 mt-4 text-sm text-white/80'>
                            {modalEnabled?.achievement?.description?.split('\n')?.map((item, index) => (
                                <p className='leading-[18px]'>{item}</p>
                            ))}
                        </div>
                    </div>
                </div>
            </Dialog>}
        </div>)
}

export default AchievementSection2
