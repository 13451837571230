import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import constants, { downloadCertificate } from '../../utilities/constants'
import { Mixpanel } from '../../config/Mixpanel'
import { toast } from 'react-toastify'
import { useAddonContext } from '../../context/AddonFeatureContext'
import { selectUniqueId } from '../../redux/authenticationSlice'
import { selectMCQDetails } from '../../redux/mcqSlice'
import { selectTotalRecords } from '../../redux/otherDetailsSlice'
import { signInForPost } from '../../redux/linkedinSlice'


export const ImageComponent = ({ source }) => (
    <img src={source} alt='' className='w-4 h-4' />
)

const CertificateDownload = React.memo(({ skillName, certificateUrl, linkedInUrl, redirectUrl }) => {

    const quizDetails = useSelector(selectMCQDetails)
    const uniqueCertificateId = useSelector(selectUniqueId)
    const totalRecordDetail = useSelector(selectTotalRecords)

    const rank = quizDetails?.user_rank
    const score = quizDetails?.quiz_score
    const totalRecords = totalRecordDetail?.total_records
    
    const [ downloaded, setDownloaded ] = useState(false)

    const { email } = useAddonContext()

    const dispatch = useDispatch()
    
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    
    useEffect(() => {
        if(downloaded){
            toast.success("Successfully Downloaded Your Certificate")
            setDownloaded(false)
        }
    }, [downloaded])

    const handleDownload = () => {
        Mixpanel.track(constants.Mixpanel.NEW_POST_PAYMENT_FEATURE_CERTIFICATE_DOWNLOAD_BUTTON_CLICKED, {
            distinct_id: email
        })
        downloadCertificate(certificateUrl, setDownloaded)
    }

    const handleShare = async() => {
        if (!linkedInUrl) return toast.warning('Please wait while we generate you link')
        try {
            Mixpanel.track(constants.Mixpanel.NEW_POST_PAYMENT_FEATURE_CERTIFICATE_SHARE_BUTTON_CLICKED)
            const response = await dispatch(signInForPost({ redirect_uri: redirectUrl }))
            window.open(`https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${skillName}&organizationId=13328177&issueYear=${year}&issueMonth=${month}&certId=${uniqueCertificateId?.unique_certificate_id}&certUrl=${linkedInUrl}`,'_blank')
            window.open(response.payload, '_self')
        } catch (error) {
            console.log(error.message)
        }
    }

    return (
        <div className='bg-[#2D4F71] rounded-2xl p-3 mt-5 relative'>
            <div className='flex gap-2 items-center pb-2'>
                <div className='w-6 h-6 bg-[#00C838] rounded-full flex justify-center items-center'>
                    <FontAwesomeIcon icon={faCheck} className='font-bold'/>
                </div>
                <div className='flex-1'>
                    <p className='font-medium'>{skillName}</p>
                    <div className='flex items-center'>
                        <p className='text-sm pr-3 border-r border-[#346DA6]'><span className='text-white/50'>Score:</span> <span className='font-bold'>{score}%</span></p>
                        <p className='text-sm pl-3'><span className='text-white/50'>Rank: </span><span className='font-bold'>{rank}</span>/{totalRecords}</p>
                    </div>
                </div>
            </div>
            <div className='p-3 bg-[#14395F] flex items-center gap-2 text-sm font-medium rounded-xl'>
                <div className='w-[60px] relative'>
                    <img src={certificateUrl} width={60} height={42} alt="" className='w-full h-auto object-cover'/>
                    <img src="https://ik.imagekit.io/238r9emirjj/Post%20Payment/Share.svg?updatedAt=1704870620830" alt="" className='absolute inset-0 mx-auto my-auto z-50 cursor-pointer' onClick={() => handleShare()}/>
                </div>
                <p className='flex-1'>Great job! <span className='text-[#7FC241] underline cursor-pointer' onClick={handleDownload}>Download </span> your certificate or directly <span className='text-[#7FC241] underline cursor-pointer' onClick={handleShare}>Share</span> on Linkedin.</p>
            </div>
            <div className='absolute bg-[#55A47E] w-[18px] h-[30px] -bottom-[30px] left-[35px]'/>
        </div>
    )
})

export default CertificateDownload