import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const signInForPost = createAsyncThunk(
    'signInForPost',
    async ({ redirect_uri }) => {
        try {

            const response = await axios.get('https://xgfy-czuw-092q.m2.xano.io/api:UpsZVD6L/oauth/linkedin/init', {
                params: {
                    redirect_uri
                }
            })
            return response.data
        } catch (error) {
            console.log(error)
        }
    }
)

export const shareLinkedInPost = createAsyncThunk(
    'shareLinkedInPost',
    async ({ code, redirect_uri, post_title, post_desc, post_comment, image_url }) => {
        try {
            await axios.post('https://xgfy-czuw-092q.m2.xano.io/api:UpsZVD6L/linkedin/share_image', {
                code,
                redirect_uri,
                post_title,
                post_desc,
                post_comment,
                post_url: '',
                image_url
            })
        } catch (error) {
            console.log(error.message)
        }
    }
)


const initialState = {
    url: '',
    code: ''
}

const linkedInSlice = createSlice({
    name: 'linkedin',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(signInForPost.fulfilled, (state, action) => {
            state.url = action.payload
        })
        builder.addCase(shareLinkedInPost.fulfilled, (state, action) => {
            state.code = action.payload
        })
    }
})

export default linkedInSlice.reducer