import { createSlice, createAsyncThunk  } from "@reduxjs/toolkit"
import axios from "axios"

export const fetchInfluencerDetail = createAsyncThunk(
    'fetch-influencer-detail',
    async (creator_slug) => {
        try {
            const response = await axios.get(`https://xgfy-czuw-092q.m2.xano.io/api:ZZCtun0K/fetch_creator_courses?creator_slug=${creator_slug}`)
            return response.data
        } catch (error) {
            console.log(error.message)
        }
    }
)

export const fetchRelatedJobs = createAsyncThunk(
    'fetchRelatedJobs',
    async ({ subject }) => {
        try {
            const response = await axios.post('https://certified-backend.learntube.ai/job_names', {
                subject
            })
            return response.data
        } catch (error) {
            console.log(error.message)
        }
    }
)

const initialState = {
    loading: false,
    influencerDetail: {},
    relatedJobs: []
}

const influencerSlice = createSlice({
    name: 'influencerDetail',
    initialState,
    reducers: {
        resetInfluencerData: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(fetchInfluencerDetail.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchInfluencerDetail.fulfilled, (state, action) => {
            state.influencerDetail = action.payload
            state.loading = false
        })
        builder.addCase(fetchRelatedJobs.fulfilled, (state, action) => {
            state.relatedJobs = action.payload
        })
    }
})

export const { resetInfluencerData } = influencerSlice.actions
export const selectInfluencerDetail = (state) => state.influencerDetails?.influencerDetail
export const selectRelatedJobs = (state) => state.influencerDetails?.relatedJobs
export default influencerSlice.reducer