import React from 'react'
import Lottie from 'react-lottie';

const LottieAnimation = ({ animation, loop }) => {
    const defaultOptions = {
        loop,
        autoplay: true,
        animationData: animation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };
    return <Lottie options={defaultOptions} />;
}

export default LottieAnimation