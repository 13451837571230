import { faChevronLeft, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectCertificateImage } from '../../redux/postPaymentSlice'
import { Mixpanel } from '../../config/Mixpanel'
import constants from '../../utilities/constants'

const coursePoints = [
    'Pre-Recorded, Learn At Your Pace',
    '24/7 Live Doubt Solving',
    'Certificate For Each Topic',
    'Personalised Path, 3X Faster Progress',
    'Tests with Real-Time Feedback'
]

const certificatePoints = [
    'Certify Skills In 10 Minutes',
    'Recognised By 1,100+ Companies',
    'Share On LinkedIn & CV',
    'Get 64% More Recruiter Replies'
]

const CertificateImageModalInside = ({ handleModal, setShowCertificateImageModalInside }) => {

    const certificateImage = useSelector(selectCertificateImage)

    const courseImage = useMemo(() => certificateImage === 'https://ik.imagekit.io/238r9emirjj/post-payment-updates/Group%2022085%20(1).png?updatedAt=1729606706291', [certificateImage])

    const points = useMemo(() => courseImage ? coursePoints : certificatePoints, [courseImage]) 

    useEffect(() => {
        Mixpanel.track(courseImage ? constants.Mixpanel.COURSE_CERTIFICATE_MODAL_OPENED : constants.Mixpanel.CERTIFICATE_MODAL_OPENED)
    }, [courseImage])

    return (
        <div className='p-3'>
            <div className='flex gap-2 items-center justify-between mb-3'>
                {(setShowCertificateImageModalInside) && <div className='cursor-pointer' 
                    onClick={() =>{
                        setShowCertificateImageModalInside(false)   
                    }}
                >
                    <FontAwesomeIcon icon={faChevronLeft} className='text-2xl'/>
                </div>}
                <h3 className='text-xl font-bold flex-1 mx-auto'>{courseImage ? 'Certificate Of Completion' : 'Certificate Of Assessment'}</h3>
                <div className='cursor-pointer' onClick={handleModal}>
                    <FontAwesomeIcon icon={faXmark} className='text-2xl'/>
                </div>
            </div>
            <img src={certificateImage} alt="" />
            <div className='mt-4'>
                <h3 className='text-sm font-semibold'>Why This Certificate Matters?</h3>
                <div className='flex flex-col gap-2 mt-3'>
                    {points?.map((item, index) => (
                        <div key={index} className='flex items-center gap-2 p-2 bg-bg-2 rounded-lg'>
                            <img src="https://ik.imagekit.io/238r9emirjj/post-payment-updates/fluent_star-checkmark-20-regular.svg?updatedAt=1727506495596" width={16} height={16} alt="" />
                            <p className='flex-1 text-xs'>{item}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default CertificateImageModalInside