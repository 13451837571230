import React, { useEffect, useState } from 'react'
import { useAddonContext } from '../../context/AddonFeatureContext'
import { ClipLoader } from 'react-spinners'
import { Mixpanel } from '../../config/Mixpanel'
import constants from '../../utilities/constants'
import { selectSelectedItems } from '../../redux/searchSlice'
import { useSelector } from 'react-redux'
import { selectMCQDetails, selectTypeformData } from '../../redux/mcqSlice'

const AddonStickyBar = ({ minutes, seconds, price, handleDiscountedPrice, handleTotalPrice, razorpayHandler, setLoading, loading }) => {

    const selectedSkills = useSelector(selectSelectedItems)
    const typeformData = useSelector(selectTypeformData)
    const quizDetails = useSelector(selectMCQDetails)

    const score = quizDetails?.quiz_score

    const { threeCertificates, fiveCertificates, sevenCertificates, personalisedSelected, proMembershipSelected, showStickyBar2, selectedAtleastOne, setProMembershipSelected, setPersonalisedSelected, fourWeekPlacementFeature, oneOnOneFeature, personaliseAndCertificate, setSelectedItems } = useAddonContext()
    const [ stickyButtonClicked, setStickyButtonClicked ] = useState(false)

    useEffect(() => {
        if (score >= 40){
            if (stickyButtonClicked && personalisedSelected && proMembershipSelected){
                razorpayHandler(() => setLoading(false))
                setStickyButtonClicked(false)
            }
        }else{
            if (stickyButtonClicked && proMembershipSelected){
                razorpayHandler(() => setLoading(false))
                setStickyButtonClicked(false)
            } 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stickyButtonClicked, personalisedSelected, proMembershipSelected, score])
    
    const razorpayClick = () => {
        setStickyButtonClicked(true)
        setSelectedItems((prev) => {
            if (score >= 40){
                return [...prev, {
                    product_slug: 'core_product_purchase',
                    product_quantity: 1,
                }, {
                    product_slug: 'personalized',
                    product_quantity: 1,
                    entity_type: 'skill',
                    entity_id: typeformData?.id
                }]
            }
            return [
                ...prev,
                {
                    product_slug: 'core_product_purchase',
                    product_quantity: 1,
                }
            ]
        })
        if (score >= 40){
            setPersonalisedSelected(true)
        }
        setProMembershipSelected(true)
        setLoading(true)
    }
    
    return (
        <>
            {(showStickyBar2 || personalisedSelected || proMembershipSelected || fourWeekPlacementFeature || oneOnOneFeature) && <div className='fixed text-white bottom-0 w-full lg:px-10 mx-auto flex gap-2 justify-between bg-[#26588B] z-10 px-2 py-3 left-1/2 -translate-x-1/2'>
               
                {(personalisedSelected || proMembershipSelected || fourWeekPlacementFeature || oneOnOneFeature) ? <>
                    <div className='flex flex-col'>
                        <p className='text-xs font-semibold'>{minutes ===0 && seconds === 0 ? 'Time Up. Buy Now!' : 'Discount Ends In:'}</p>
                        <div className='border-2 mt-1 w-[90px] border-danger text-danger flex rounded justify-center py-1 px-1.5'>
                            <p className='text-sm font-extrabold'>{minutes.toString().padStart(2, '0')}M :</p>
                            <p className='text-sm font-extrabold ml-1'>{seconds.toString().padStart(2, '0')}S</p>
                        </div>
                    </div>
                    <div className='flex flex-col items-center'>
                        <div className={`border-2 text-center cursor-pointer min-w-[126px] w-full flex justify-center items-center text-sm font-semibold border-[#7FC241] ${((score >= 40 && personalisedSelected) && proMembershipSelected) || (fourWeekPlacementFeature && proMembershipSelected) ? 'text-black bg-[#7FC241] btn--glow' : 'text-[#7FC241]'} px-4 py-2 rounded-lg`} onClick={() => {
                            Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_STICKY_BAR_GET_CERTIFICATE_BUTTON)
                            setLoading(true)
                            razorpayHandler(() => setLoading(false))
                        }}>
                            {!loading ? `Get Certificate${ selectedAtleastOne || selectedSkills?.length > 0 ? 's' : ''}${(fourWeekPlacementFeature && proMembershipSelected) ? ', Courses & Placement' :(personalisedSelected && proMembershipSelected) || proMembershipSelected ? ' & Courses' : (fourWeekPlacementFeature && personalisedSelected) ? ', Course & Placement' : personalisedSelected ? ' & Course' : fourWeekPlacementFeature ? ' & Placement' : ''}` : <ClipLoader size={20} color={`${((personalisedSelected || fourWeekPlacementFeature) && proMembershipSelected ) ? 'black' : '#7FC241'}`} />}
                        </div>
                        <p className='text-[10px] mt-1'>Only <span className='text-secondary-text'>₹{(personaliseAndCertificate ? (100 + price.price) : price.price) + handleDiscountedPrice()}</span> <span className='text-danger'>₹</span><span className='text-danger line-through'>{price?.original_price + handleTotalPrice()}</span></p>
                    </div>
                </> : <div className='w-full mx-2 flex flex-col items-center mt-0.5'>
                    <div className='w-full text-center md:w-2/5 rounded-lg text-sm font-semibold text-black my-1 bg-[#7FC241] p-2 cursor-pointer' onClick={() => {
                        Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_BOTTOM_STICKY_ADDING_BOTH_ADDON_BUTTON)
                        razorpayClick()
                    }}>
                        {score < 40 ? 'Add 1240+ Courses' : 'Add The Personalised Course & 1240+ Courses'}
                    </div>
                    <p className='text-sm text-[#7FC241] font-semibold mt-1.5'>Or <span className='underline cursor-pointer' onClick={() => {
                        Mixpanel.track(constants.Mixpanel.NEW_ADDON_FEATURE_PERSONAL_AND_PRO_MEMBERSHIP_BOTTOM_STICKY_SKIP_BUTTON)
                        razorpayHandler(() => setLoading(false))
                    }}>Continue With Only {threeCertificates ? 4 : fiveCertificates ? 6 : sevenCertificates ? 8 : selectedSkills?.length > 0 ? selectedSkills?.length + 1 : 1} Certificate{selectedAtleastOne || selectedSkills?.length > 0 ? 's' : ''}</span></p>
                </div>}
            </div>}
        </>
    )
}

export default AddonStickyBar