import { createSlice } from '@reduxjs/toolkit';


const initialState = []
// Redux slice for questions
const answersSlice = createSlice({
    name: 'answers',
    initialState,
    reducers: {
        resetAnswers: (state) => initialState,
        setAnswers: (state, actions) => {
            const { answer, index } = actions.payload
            if (state[index]){
                state[index] = answer
            }else {
                state.push(answer)
            }
        }

    },
});

export const { resetAnswers, setAnswers } = answersSlice.actions
export const selectAnswers = (state) => state.answers;
export default answersSlice.reducer;