import store from './store'
import React from 'react'
import ReactDOM from 'react-dom/client'
import {BrowserRouter as Router} from 'react-router-dom'
import './index.css'
import App from './App'
import { Provider } from 'react-redux'
import { ENV } from './config/api_config'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import AddonFeatureProvider from './context/AddonFeatureContext'
import * as Sentry from "@sentry/react"


const root = ReactDOM.createRoot(document.getElementById('root'));
let persistor = persistStore(store)

Sentry.init({
    dsn: "https://a3c4a094f3d909ba6526f9ce57c9a48d@o4506432332627968.ingest.sentry.io/4506596420747264",
    integrations: [
        new Sentry.BrowserTracing({
            tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        }),
        new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
})

root.render(
  
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <AddonFeatureProvider>
                <Router>
                    <App />
                </Router>
            </AddonFeatureProvider>
        </PersistGate>
    </Provider>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (ENV === "PRODUCTION") console.log = function () { };